import React, { Fragment } from "react";
import "./RestaurantCard.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getrestaurantDetails } from "store/restaurants/restaurants.actions";

const RestaurantCard = ({
  data,
  isUserFvrtRestaurants,
  forwardPath,
  history,
  getDetails
}) => {
  return (
    <Fragment>
      {data &&
        data.map(item => (
          <div
            className={
              isUserFvrtRestaurants
                ? "user-fvrt-restaurants mt-3 mr-2"
                : "card-container mt-3 mr-2"
            }
            onClick={() => {
              getDetails(item._id);
              history.push(forwardPath);
            }}
          >
            <img src={item.image} className="card-head-img" alt="..." />
            <div className="restaurant-info">
              <h4>{item.name}</h4>
              <p className="text-center">{item.address}</p>
            </div>
          </div>
        ))}
    </Fragment>
  );
};
const mapDisptchToProps = dispatch => ({
  getDetails: restaurantId => dispatch(getrestaurantDetails(restaurantId))
});
export default connect(null, mapDisptchToProps)(withRouter(RestaurantCard));
