import React, { Component } from 'react';
import NavBar from '../../components/NavBar/NavBar';

class BusinessEnvironment extends Component {
    render() {
        return (
            <>
            <NavBar/>
            <div className="container-fluid flexer business-envi-height" >
                <div className="row vw-100 flexer business-envi-height">
                    <div className="col-lg-6 col-md-10 col-sm-10 info-container-card">

                        <div className="info-heading-wrapper">
                            <h1 className="info-heading">Business Environment</h1>
                        </div>
                        <form className="flexer-column" style={{ width: '80%' }}>

                            <div className='business-info-input-field-wrapper' style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '48%' }}>
                                    <label className="input-label w-100">
                                        ANNUAL REY.
                                    </label>
                                    <input
                                        //   onChange={this.handleChange}
                                        type="text"
                                        //   value={password}
                                        name=""
                                        className="business-info-input-field TB w-100"
                                        
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <label className="input-label w-100">
                                        TOT. MONTHLY SALES
                                    </label>
                                    <input
                                        //   onChange={this.handleChange}
                                        type="text"
                                        //   value={password}
                                        name=""
                                        className="business-info-input-field TB w-100"
                                        
                                    />
                                </div>
                            </div>

                            <div className='business-info-input-field-wrapper' style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '48%' }}>
                                    <label className="input-label w-100">
                                        AVG TICKET
                                    </label>
                                    <input
                                        //   onChange={this.handleChange}
                                        type="text"
                                        //   value={password}
                                        name=""
                                        className="business-info-input-field TB w-100"
                                        
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <label className="input-label w-100">
                                        HIGHEST AMOUNT
                                    </label>
                                    <select
                                        //   onChange={this.handleChange}
                                        //   value={password}
                                        name=""
                                        className="business-info-input-field TB w-100"
                                        
                                    >
                                        <option>Select City</option>
                                    </select>
                                </div>
                            </div>

                            <div className='business-info-input-field-wrapper w-100'>
                                <label className="input-label w-100">
                                    CUST. SERVICE PHONE
                            </label>
                                <input
                                    //   onChange={this.handleChange}
                                    type="text"
                                    //   value={email}
                                    name="email"
                                    className="business-info-input-field w-100"
                                    
                                />
                            </div>

                            <div className='business-info-input-field-wrapper' style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '48%' }}>
                                    <label className="input-label w-100">
                                        TICKET FOR YEAR
                                    </label>
                                    <input
                                        //   onChange={this.handleChange}
                                        type="text"
                                        //   value={password}
                                        name=""
                                        className="business-info-input-field TB w-100"
                                        
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <label className="input-label w-100">
                                        PRODUCT WEBSITE
                                    </label>
                                    <input
                                        //   onChange={this.handleChange}
                                        type="text"
                                        //   value={password}
                                        name=""
                                        className="business-info-input-field TB w-100"
                                        
                                    />
                                </div>
                            </div>

                            <div className='business-info-input-field-wrapper' style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '48%' }}>
                                    <label className="input-label w-100">
                                        ABA/ROUNTING #
                                    </label>
                                    <input
                                        //   onChange={this.handleChange}
                                        type="text"
                                        //   value={password}
                                        name=""
                                        className="business-info-input-field TB w-100"
                                        
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <label className="input-label w-100">
                                        DDA ACCOUNT #
                                    </label>
                                    <select
                                        //   onChange={this.handleChange}
                                        //   value={password}
                                        name=""
                                        className="business-info-input-field TB w-100"
                                        
                                    >
                                        <option>Select City</option>
                                    </select>
                                </div>
                            </div>

                        </form>
                        <div className="w-100" style={{ display: 'flex' }}>
                            <button
                                //   onClick={this.handleSubmit}
                                type="submit"
                                className="w-100 business-info-btn"
                            >
                                Save Progress
                            </button>
                            <button
                                //   onClick={this.handleSubmit}
                                type="submit"
                                className="w-100 business-info-btn"
                            >
                                Next
                            </button>

                        </div>
                    </div>
                </div>
            </div>
            </>
        );
    }
}


export default BusinessEnvironment;