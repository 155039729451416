import { ordersActionsTypes } from "./orders.action.types";
const INITIAL_STATE = {
  orders: null,
  isloading: false,
  message: null,
  orderDetail: {}
};

const ordersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ordersActionsTypes.GET_ORDERS_REQUEST:
      return Object.assign({
        ...state,
        isloading: true
      });
    case ordersActionsTypes.GET_ORDERS_SUCCESS:
      return Object.assign({
        ...state,
        isloading: false,
        orders: action.payload,
        message: "get orders success"
      });
    case ordersActionsTypes.GET_ORDERS_FAILURE:
      return Object.assign({
        ...state,
        isloading: false,
        message: action.payload
      });
    case ordersActionsTypes.GET_ORDER_DETAILS:
      return Object.assign({
        ...state,
        orderDetail: state.orders.find(order => order._id === action.payload)
      });
    default:
      return state;
  }
};

export default ordersReducer;
