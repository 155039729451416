import { restuarantsActionsTypes } from "./restaurants.action.types";
const INITIAL_STATE = {
  restaurants: null,
  isloading: false,
  message: null,
  restaurantDetail: "",
  menu: null,
  offers: null,
  giftCards: null,
  reservations: null,
  branches: null,
  rewards: null,
  branchesForLocation: null,
  addons:[]
};

const restaurantsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case restuarantsActionsTypes.GET_RESTAURANTS_REQUEST:
      return Object.assign({
        ...state,
        restaurants: null,
        isloading: true
      });
    case restuarantsActionsTypes.GET_RESTAURANTS_SUCCESS:
      return Object.assign({
        ...state,
        isloading: false,
        restaurants: action.payload,
        message: "get restaurants success"
      });
    case restuarantsActionsTypes.GET_RESTAURANTS_FAILURE:
      return Object.assign({
        ...state,
        isloading: false,
        message: action.payload
      });
    case restuarantsActionsTypes.GET_RESTAURANT_MENU_REQUEST:
      return Object.assign({
        ...state,
        isloading: true
      });
    case restuarantsActionsTypes.GET_RESTAURANT_MENU_SUCCESS:
      return Object.assign({
        ...state,
        isloading: false,
        menu: action.payload,
        message: "get menu success"
      });
    case restuarantsActionsTypes.GET_RESTAURANT_OFFER_REQUEST:
      return Object.assign({
        ...state,
        isloading: true,
        message: action.payload
      });
    case restuarantsActionsTypes.GET_RESTAURANT_OFFER_SUCCESS:
      return Object.assign({
        ...state,
        isloading: false,
        offers: action.payload,
        message: "get offers success"
      });
    case restuarantsActionsTypes.GET_RESTAURANT_OFFER_FAILURE:
      return Object.assign({
        ...state,
        isloading: false,
        message: action.payload
      });
    case restuarantsActionsTypes.GET_RESTAURANT_GIFT_CARDS_REQUEST:
      return Object.assign({
        ...state,
        isloading: true,
        message: action.payload
      });
    case restuarantsActionsTypes.GET_RESTAURANT_GIFT_CARDS_SUCCESS:
      return Object.assign({
        ...state,
        isloading: false,
        giftCards: action.payload,
        message: "get giftCards success"
      });
    case restuarantsActionsTypes.GET_RESTAURANT_GIFT_CARDS_FAILURE:
      return Object.assign({
        ...state,
        isloading: false,
        message: action.payload
      });
    case restuarantsActionsTypes.GET_RESTAURANT_RESERVATIONS_REQUEST:
      return Object.assign({
        ...state,
        isloading: true,
        message: action.payload
      });
    case restuarantsActionsTypes.GET_RESTAURANT_RESERVATIONS_SUCCESS:
      return Object.assign({
        ...state,
        isloading: false,
        reservations: action.payload,
        message: "get reservations success"
      });
    case restuarantsActionsTypes.GET_RESTAURANT_RESERVATIONS_FAILURE:
      return Object.assign({
        ...state,
        isloading: false,
        message: action.payload
      });
    case restuarantsActionsTypes.GET_RESTAURANT_BRANCHES_REQUEST:
      return Object.assign({
        ...state,
        isloading: true,
        message: action.payload
      });
    case restuarantsActionsTypes.GET_RESTAURANT_BRANCHES_SUCCESS:
      return Object.assign({
        ...state,
        isloading: false,
        branches: action.payload,
        message: "get branches success"
      });
    case restuarantsActionsTypes.GET_RESTAURANT_BRANCHES_FOR_LOCATION_SUCCESS:
      return Object.assign({
        ...state,
        isloading: false,
        branchesForLocation: action.payload,
        message: "get branches success"
      });
    case restuarantsActionsTypes.GET_RESTAURANT_BRANCHES_FAILURE:
      return Object.assign({
        ...state,
        isloading: false,
        message: action.payload
      });
    case restuarantsActionsTypes.GET_RESTAURANTS_DETAILS:
      return Object.assign({
        ...state,
        restaurantDetail: state.restaurants.find(
          restaurant => restaurant._id === action.payload
        )
      });

    case restuarantsActionsTypes.GET_RESTAURANT_REWARDS_REQUEST:
      return Object.assign({
        ...state,
        isloading: true,
        message: action.payload
      });
    case restuarantsActionsTypes.GET_RESTAURANT_REWARDS_SUCCESS:
      return Object.assign({
        ...state,
        isloading: false,
        rewards: action.payload,
        message: "get rewards success"
      });
    case restuarantsActionsTypes.GET_RESTAURANT_REWARDS_FAILURE:
      return Object.assign({
        ...state,
        isloading: false,
        message: action.payload
      });

    case restuarantsActionsTypes.UPDATE_RESTAURANT_REQUEST:
      return Object.assign({
        ...state,
        isloading: true,
        message: action.payload
      });
    case restuarantsActionsTypes.UPDATE_RESTAURANT_SUCCESS:
      const restaurantId = action.payload.restaurantId;
      const statusToUpdate = action.payload.status;
      const restaurants = state.restaurants;
      restaurants.forEach(item => {
        if (item._id === restaurantId) {
          item.status = statusToUpdate;
        }
      });
      return Object.assign({
        ...state,
        isloading: false,
        restaurants: restaurants,
        message: "Restaurant update successfully"
      });
    case restuarantsActionsTypes.UPDATE_RESTAURANT_FAILURE:
      return Object.assign({
        ...state,
        isloading: false,
        message: action.payload
      });
    case restuarantsActionsTypes.GET_RESTAURANT_ADDONS_REQUEST:
      return Object.assign({
        ...state,
        isloading: true,
        message: action.payload
      });
    case restuarantsActionsTypes.GET_RESTAURANT_ADDONS_SUCCESS:
      return Object.assign({
        ...state,
        isloading: false,
        addons: action.payload,
        message: "get addons success"
      });
    case restuarantsActionsTypes.GET_RESTAURANT_ADDONS_FAILURE:
      return Object.assign({
        ...state,
        isloading: false,
        message: action.payload
      });
    default:
      return state;
  }
};

export default restaurantsReducer;
