import React, { Fragment } from "react";
import { Column } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite/no-important";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const styles = StyleSheet.create({
  addonCard: {
    marginTop: 20,
    backgroundColor: "white",
    borderRadius: 5,
    padding: "5px 20px 5px 5px",
    boxShadow: "0 3px 10px -3px grey",
    width: "30%",
    marginLeft:"10px",
  },
  userRow: {
    width: "100%",
    padding: 5
  },
  giftCardImage: {
    height: 100,
    width: 150,
    marginRight: 20
  },
  mainText: {
    fontWeight: "800",
    fontSize: 15,
    margin: 0,
    padding: 0
  },
  subText: {
    color: "#b2b2b2",
    fontWeight: 500,
    letterSpacing: 0.3,
    marginTop: 10
  },
  restaurantInfo: {
    fontWeight: "bold",
    textTransform: "capitalize"
  },
  wrapper: {
    marginBottom: 5,
    "@media (max-width: 415px)": {
      fontSize: 10,
      textAlign: "justify"
    }
  }
});

const AddOnTab = props => {
  const { addons } = props;
  return (
    <Fragment>
      {addons &&
        addons.map((item, index) => (
          <Column
            horizontal="center"
            vertical="center"
            className={css(styles.addonCard)}
          >
            <div
              style={{
                width: "100%",
                padding: 5,
              }}
              className="p-3"
            >
              <p className="font-weight-bolder">
                {item.name}
              </p>
            </div>
          </Column>
        ))}
    </Fragment>
  );
};
const mapStateToProps = ({ restaurants: { addons } }) => ({ addons });

export default connect(mapStateToProps)(withRouter(AddOnTab));
