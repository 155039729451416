import React, { Component } from 'react';
import './NavBar.css';

class NavBar extends Component {
    render() {
        return (
            <div className='nav-bar-container'>
                <h1 className='nav-heading'>NMI APPLICATION FORM</h1>
            </div>
        );
    }
}

export default NavBar;