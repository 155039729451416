import React from "react";
import "./loader.css";
const Loader = () => (
  <div className="LoaderContainer">
    <div className="loader">
      <div className="lds-facebook">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
);

export default Loader;
