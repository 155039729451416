import React, { Fragment, Component } from "react";
import { searchBoxData } from "./data";
import SearchBox from "components/SeachBox/SearchBox";
import OrdersList from "components/OrdersList/OrdersList";
import { connect } from "react-redux";
import { getordersMiddleware } from "./../../store/orders/orders.middleware";
import Loader from "components/Loader/Loader";

class Orders extends Component {
  componentDidMount() {
    const { token, getOrders } = this.props;
    getOrders({ token });
  }
  filterOrders = filter => {
    const { token, getOrders } = this.props;
    filter === "Show All"
      ? getOrders({ token })
      : getOrders({ token, filter: filter.toLowerCase() });
  };
  render() {
    const { orders, isloading } = this.props;
    return (
      <Fragment>
        <SearchBox
          heading={"Orders"}
          searchBoxData={searchBoxData}
          filterFunction={this.filterOrders}
        />
        <OrdersList
          data={orders ? orders : []}
          isOrderPage={true}
          forwardPath={"/orders/details"}
        />
        {!!isloading && <Loader />}
      </Fragment>
    );
  }
}
const mapStateToProps = ({
  orders: { orders, isloading },
  admin: { token }
}) => ({ orders, isloading, token });
const mapDispatchToProps = dispatch => ({
  getOrders: data => dispatch(getordersMiddleware(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(Orders);
