export const adminActionTypes = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  ADIMIN_EDIT_PROFILE_REQUEST: "ADIMIN_EDIT_PROFILE_REQUEST",
  ADIMIN_EDIT_PROFILE_SUCCESS: "ADIMIN_EDIT_PROFILE_SUCCESS",
  ADIMIN_EDIT_PROFILE_FAILURE: "ADIMIN_EDIT_PROFILE_FAILURE",

  SEND_EMAIL_FOR_PASSWORD_RECOVERY_REQUEST:
    "SEND_EMAIL_FOR_PASSWORD_RECOVERY_REQUEST",
  SEND_EMAIL_FOR_PASSWORD_RECOVERY_SUCCESS:
    "SEND_EMAIL_FOR_PASSWORD_RECOVERY_SUCCESS",
  SEND_EMAIL_FOR_PASSWORD_RECOVERY_FAILURE:
    "SEND_EMAIL_FOR_PASSWORD_RECOVERY_FAILURE",

  VERIFY_SECURITY_CODE_REQUEST: "VERIFY_SECURITY_CODE_REQUEST",
  VERIFY_SECURITY_CODE_SUCCESS: "VERIFY_SECURITY_CODE_SUCCESS",
  VERIFY_SECURITY_CODE_FAILURE: "VERIFY_SECURITY_CODE_FAILURE",

  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",

  LOGOUT: "LOGOUT"
};
