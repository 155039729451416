import React from "react";
import { Column } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import Logo from "../../assets/icon-logo";

const styles = StyleSheet.create({
  container: {
    height: 170
  },
  title: {
    fontStyle: "normal",
    fontSize: 15,
    lineHeight: "24px",
    color: "white",
    fontWeight: "bold",
    marginTop: 15
  }
});

function LogoComponent() {
  return (
    <Column
      className={css(styles.container)}
      horizontal="center"
      vertical="center"
    >
      <Logo />
      <span className={css(styles.title)}>Merchant Nation</span>
    </Column>
  );
}

export default LogoComponent;
