export const restaurantReservationData = [
  {
    id: 1,
    bookingNo: "#33454677656",
    onDate: "10/7/19",
    requestDate: "5/7/19",
    time: "12:00",
    status: "Pending",
    guests: 2,
    address: "300 Florence Ave"
  },
  {
    id: 2,
    bookingNo: "#33454677656",
    onDate: "10/7/19",
    requestDate: "5/7/19",
    time: "12:00",
    status: "Pending",
    guests: 2,
    address: "300 Florence Ave"
  },
  {
    id: 3,
    bookingNo: "#33454677656",
    onDate: "10/7/19",
    requestDate: "5/7/19",
    time: "12:00",
    status: "Pending",
    guests: 2,
    address: "300 Florence Ave"
  },
  {
    id: 4,
    bookingNo: "#33454677656",
    onDate: "10/7/19",
    requestDate: "5/7/19",
    time: "12:00",
    status: "Pending",
    guests: 2,
    address: "300 Florence Ave"
  },
  {
    id: 5,
    bookingNo: "#33454677656",
    onDate: "10/7/19",
    requestDate: "5/7/19",
    time: "12:00",
    status: "Pending",
    guests: 2,
    address: "300 Florence Ave"
  },
  {
    id: 6,
    bookingNo: "#33454677656",
    onDate: "10/7/19",
    requestDate: "5/7/19",
    time: "12:00",
    status: "Pending",
    guests: 2,
    address: "300 Florence Ave"
  },
  {
    id: 7,
    bookingNo: "#33454677656",
    onDate: "10/7/19",
    requestDate: "5/7/19",
    time: "12:00",
    status: "Pending",
    guests: 2,
    address: "300 Florence Ave"
  },
  {
    id: 8,
    bookingNo: "#33454677656",
    onDate: "10/7/19",
    requestDate: "5/7/19",
    time: "12:00",
    status: "Pending",
    guests: 2,
    address: "300 Florence Ave"
  }
];
