import React, { Component, Fragment } from "react";
import "./ForgotPassword.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import HeaderComponent from "components/header/HeaderComponent";
import { Column } from "simple-flexbox";
import { connect } from "react-redux";
import {
  sendRecoveryEmailMiddleware,
  resetPasswordMiddleware,
  verifyCodeMiddleware
} from "store/admin/admin.middleware";
import { Modal } from "antd";
import Loader from "components/Loader/Loader";

class ForgotPassword extends Component {
  state = {
    email: "",
    type: "admin",
    id: "",
    newPassword: "",
    confirmPassword: "",
    code: ""
  };

  verifySecurityCode = event => {
    event.preventDefault();
    const { code, type } = this.state;
    if (!code) {
      return Modal.error({
        content: "Please Enter Your Security Code"
      });
    } else {
      this.props.verifyCode({ code, type });
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    const { email, type } = this.state;
    if (!email) {
      return Modal.error({
        content: "please enter your email"
      });
    } else {
      this.props.sendRecoveryEmail({ email, type });
    }
  };

  submitNewPassword = event => {
    event.preventDefault();
    const { newPassword, confirmPassword } = this.state;
    const { id } = this.props;

    if (!newPassword || !confirmPassword || !id) {
      return Modal.error({
        content: "Please enter complete details"
      });
    } else if (newPassword !== confirmPassword) {
      return Modal.error({
        content: `Password didn't match`
      });
    }
    this.props.resetPassword({ newPassword, id });
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { isloading, isRecoveryEmailSent, isVerifyCode } = this.props;
    const { email, newPassword, confirmPassword, code } = this.state;
    return (
      <div className="flexer ">
        <Column
          style={{ width: "80%" }}
          className="vh-100"
          vertical="center"
          horizontal="center"
        >
          <HeaderComponent isBackBtn={true} />
          <div className="row w-100 flexer h-100">
            <div
              className="col-lg-6 col-md-10 col-sm-10 forget-password-container-card"
              style={{ height: `${isVerifyCode ? "70%" : "55%"}` }}
            >
              {isRecoveryEmailSent && (
                <Fragment>
                  <h3 className="heading">Verify Your Security Code</h3>
                  <form
                    className="forget-password-form w-75"
                    onSubmit={event => this.verifySecurityCode(event)}
                  >
                    <input
                      type="number"
                      placeholder="Enter Your Security Code *"
                      name="code"
                      value={code}
                      onChange={this.handleChange}
                      className="email-field w-100"
                    />
                    <button
                      type="submit"
                      className="w-100 forget-password-btn mt-5"
                    >
                      Verify Code
                      <FontAwesomeIcon
                        className="mt-1 mr-3"
                        style={{ float: "right" }}
                        icon={faArrowRight}
                      />
                    </button>
                  </form>
                </Fragment>
              )}

              {isVerifyCode && !isRecoveryEmailSent && (
                <Fragment>
                  <h3 className="heading">Reset Your Password</h3>
                  <form
                    className="forget-password-form w-75"
                    onSubmit={event => this.submitNewPassword(event)}
                  >
                    <input
                      type="password"
                      placeholder="New Password *"
                      name="newPassword"
                      value={newPassword}
                      onChange={this.handleChange}
                      className="email-field w-100"
                    />
                    <input
                      type="password"
                      placeholder="Confirm Password *"
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={this.handleChange}
                      className="email-field w-100"
                    />
                    <button
                      type="submit"
                      className="w-100 forget-password-btn mt-5"
                    >
                      Reset Password
                      <FontAwesomeIcon
                        className="mt-1 mr-3"
                        style={{ float: "right" }}
                        icon={faArrowRight}
                      />
                    </button>
                  </form>
                </Fragment>
              )}

              {!isRecoveryEmailSent && !isVerifyCode && (
                <Fragment>
                  <h3 className="heading">Enter Your Email Address</h3>
                  <form
                    className="forget-password-form w-75"
                    onSubmit={event => this.handleSubmit(event)}
                  >
                    <input
                      type="email"
                      placeholder="Email *"
                      name="email"
                      value={email}
                      onChange={this.handleChange}
                      className="email-field w-100"
                    />
                    <button
                      type="submit"
                      className="w-100 forget-password-btn mt-5"
                    >
                      Send Email
                      <FontAwesomeIcon
                        className="mt-1 mr-3"
                        style={{ float: "right" }}
                        icon={faArrowRight}
                      />
                    </button>
                  </form>
                </Fragment>
              )}
            </div>
          </div>
        </Column>
        {isloading && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = ({
  admin: { isloading, message, isRecoveryEmailSent, isVerifyCode, id }
}) => ({ isloading, message, isRecoveryEmailSent, isVerifyCode, id });
const mapDispatchToProps = dispatch => ({
  sendRecoveryEmail: data => dispatch(sendRecoveryEmailMiddleware(data)),
  resetPassword: data => dispatch(resetPasswordMiddleware(data)),
  verifyCode: data => dispatch(verifyCodeMiddleware(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
