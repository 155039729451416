import axios from "axios";
import Path from "Config/Path";
import {
  sendNotificationRequest,
  sendNotificationSuccess,
  sendNotificationFailure,
  getNotficationFailure,
  getNotficationRequest,
  getNotficationSuccess,
  getAllNotficationRequest,
  getAllNotficationSuccess,
  getAllNotficationFailure
} from "./notification.actions";
import { Modal } from "antd";

export const sendNotificationMiddleware = data => {
  return async dispatch => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.token}`
        }
      };
      dispatch(sendNotificationRequest());
      const response = await axios.post(
        Path.SEND_NOTIFICATION_TO_USER,
        data.data,
        config
      );
      if (response.status === 200) {
        dispatch(sendNotificationSuccess(response.data.message));
        Modal.success({
          title: "Success",
          content: "notifiaction scheduled."
        });
      }
    } catch (error) {
      dispatch(
        sendNotificationFailure({
          success: false,
          errorMessage: "Something went wrong please try again"
        })
      );
    }
  };
};

export const getUserNotificationMiddleware = data => {
  return async dispatch => {
    try {
      dispatch(getNotficationRequest());
      const response = await axios.get(`${Path.GET_ALL_NOTIFICATION}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.token}`
        },
      });
      if (response.status === 200) {
        dispatch(getNotficationSuccess(response.data.data.users));
      }
    } catch (error) {
      dispatch(
        getNotficationFailure({
          success: false,
          errorMessage: "Something went wrong please try again"
        })
      );
    }
  };
};

export const getAllNotficationsMiddleware = data => {
  return async dispatch => {
    try {
      dispatch(getAllNotficationRequest());
      const response = await axios.get(`${Path.GET_ALL_NOTIFICATION}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.token}`
        },
      });
      dispatch(getAllNotficationSuccess(response.data.data.schedules));
    } catch (error) {
      dispatch(
        getAllNotficationFailure({
          success: false,
          errorMessage: "Something went wrong please try again"
        })
      );
    }
  };
};
