import React, { Fragment } from "react";
import "./OfferAndPromotionCard.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const OffersAndPromotionTab = ({ offers, data }) => {
  return (
    <Fragment>
      {offers &&
        offers.map(item => (
          <div className="offer mt-3 mr-2">
            <img src={item.image} className="card-head" alt="..." />
            <div className="restaurant-offer-info flexer">
              <div className="offer-type flexer">
                <h4>{item.discount}%</h4>
              </div>
              <div className="offer-description flexer">
                <p>{item.description}</p>
              </div>
            </div>
          </div>
        ))}
    </Fragment>
  );
};
const mapStateToProps = ({ restaurants: { offers } }) => ({ offers });

export default connect(mapStateToProps)(withRouter(OffersAndPromotionTab));
