import { notificationsActionTypes } from "./notification.action.types";
import _ from "lodash";
const INITIAL_STATE = {
  notification: null,
  isloading: false,
  message: null,
  users: [],
  scheduledNotifications:[]
};

const notificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case notificationsActionTypes.NOTIFICATION_SEND_REQUEST:
      return Object.assign({
        ...state,
        isloading: true
      });
    case notificationsActionTypes.NOTIFICATION_SEND_SUCCESS:
      return Object.assign({
        ...state,
        isloading: false,
        message: action.payload
      });
    case notificationsActionTypes.NOTIFICATION_SEND_FAILURE:
      return Object.assign({
        ...state,
        isloading: false,
        message: action.payload
      });
    case notificationsActionTypes.GET_USER_NOTIFICATIONS_REQUEST:
      return Object.assign({
        ...state,
        isloading: false
      });
    case notificationsActionTypes.GET_USER_NOTIFICATIONS_SUCCESS:
      const filterData = _.uniqBy(action.payload, e => {
        return e._id;
      });
      return Object.assign({
        ...state,
        isloading: false,
        users: filterData
      });
    case notificationsActionTypes.GET_USER_NOTIFICATIONS_FAILURE:
      return Object.assign({
        ...state,
        isloading: false,
        message: action.payload
      });
    case notificationsActionTypes.GET_ALL_NOTIFICATIONS_REQUEST:
      return Object.assign({
        ...state,
        isloading: true
      });
    case notificationsActionTypes.GET_ALL_NOTIFICATIONS_SUCCESS:
      return Object.assign({
        ...state,
        isloading: false,
        scheduledNotifications: action.payload
      });
    case notificationsActionTypes.GET_ALL_NOTIFICATIONS_FAILURE:
      return Object.assign({
        ...state,
        isloading: false,
        message: action.payload
      });
    default:
      return state;
  }
};

export default notificationReducer;
