export const tabScreenData = [
  {
    tabName: "Menu",
    isActive: true
  },
  {
    tabName: "Offers & Promotion",
    isActive: false
  },
  {
    tabName: "Gift Cards",
    isActive: false
  },
  {
    tabName: "Reservations",
    isActive: false
  },
  {
    tabName: "Branches",
    isActive: false
  },
  {
    tabName: "Other Restaurants",
    isActive: false
  },
  {
    tabName: "Add-Ons",
    isActive: false
  }
];
