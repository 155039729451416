import CardImage1 from "assets/img/pizza-slices.png";
import CardImage2 from "assets/img/roll-image.png";
import CardImage3 from "assets/img/berger.png";

export const restaurantCardData = [
  {
    id: 1,
    image: CardImage2,
    restaurantName: "Fiesta Martin",
    address: "Inglewood, CA 90203"
  },

  {
    id: 2,
    image: CardImage3,
    restaurantName: "Kren's Crunch",
    address: "Inglewood, CA 90203"
  },

  {
    id: 3,
    image: CardImage1,
    restaurantName: "Rowtisseir's",
    address: "Inglewood, CA 90203"
  }
];
