import CardImage from "assets/img/orderImg.png";

export const orderDetails = [
  {
    id: 1,
    mainText: "Fiesta martin",
    subText: "3345989656",
    avatar: CardImage,
    earnings: "5.00"
  },
  {
    id: 2,
    mainText: "Fiesta martin",
    subText: "3345989656",
    avatar: CardImage,
    earnings: "5.00"
  },
  {
    id: 3,
    mainText: "Fiesta martin",
    subText: "3345989656",
    avatar: CardImage,
    earnings: "5.00"
  },
  {
    id: 4,
    mainText: "Fiesta martin",
    subText: "3345989656",
    avatar: CardImage,
    earnings: "5.00"
  },
  {
    id: 5,
    mainText: "Fiesta martin",
    subText: "3345989656",
    avatar: CardImage,
    earnings: "5.00"
  },
  {
    id: 6,
    mainText: "Fiesta martin",
    subText: "3345989656",
    avatar: CardImage,
    earnings: "5.00"
  },
  {
    id: 7,
    mainText: "Fiesta martin",
    subText: "3345989656",
    avatar: CardImage,
    earnings: "5.00"
  },
  {
    id: 8,
    mainText: "Fiesta martin",
    subText: "3345989656",
    avatar: CardImage,
    earnings: "5.00"
  }
];
