import React, { Fragment } from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite/no-important";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const styles = StyleSheet.create({
  menuTable: {
    marginTop: 20,
    backgroundColor: "white",
    borderRadius: 5,
    padding: 20,
    boxShadow: "0 3px 10px -3px grey",
    width: "100%"
  },
  userRow: {
    width: "100%",
    padding: 5
  },
  userImage: {
    height: 50,
    width: 50,
    marginRight: 5,
    borderRadius: "50%",
    marginTop: 10
  },
  mainText: {
    fontWeight: "800",
    fontSize: 15,
    margin: 0,
    padding: 0
  },
  subText: {
    color: "#b2b2b2",
    fontWeight: 500,
    letterSpacing: 0.3
  },
  restaurantInfo: {
    // fontWeight: "bold",
    textTransform: "capitalize"
  },
  wrapper: {
    marginBottom: 5,
    "@media (max-width: 415px)": {
      fontSize: 10,
      textAlign: "justify"
    }
  }
});

const MenuTab = ({ menu }) => {
  return (
    <Fragment>
      {menu &&
        menu.map((item, index) => (
          <Column
            horizontal="start"
            vertical="center"
            className={css(styles.menuTable)}
          >
            <div
              className={index !== menu.length - 1 && css(styles.userRow)}
              style={{
                width: "100%",
                padding: 5,
                paddingTop: 10,
                paddingBottom: 10
              }}
            >
              <Row horizontal="space-between" vertical="center">
                <div className="flexer">
                  <img
                    alt="avatar"
                    src={item.image}
                    className={css(styles.userImage)}
                  />
                  <Column className="ml-2 p-2 mt-2">
                    <Row className={css(styles.wrapper)}>
                      <p className={css(styles.mainText)}>{item.title} </p>
                      <p className={css(styles.restaurantInfo)}>
                        &nbsp;&nbsp;-- Category:{" "}
                        <span className="font-weight-bold">
                          {item.category.name}
                        </span>
                      </p>
                    </Row>

                    <p className={css(styles.subText)}>{item.description}</p>
                  </Column>
                </div>
                <Row horizontal="center" vertical="center" className="m-0 p-0">
                  <div className="flexer-column">
                    <p className="align-end font-weight-bolder">
                      ${item.price}
                    </p>
                  </div>
                </Row>
              </Row>
            </div>
          </Column>
        ))}
    </Fragment>
  );
};
const mapStateToProps = ({ restaurants: { menu } }) => ({ menu });

export default connect(mapStateToProps)(withRouter(MenuTab));
