import { adminActionTypes } from "./admin.action.types";
const INITIAL_STATE = {
  admin: null,
  isloading: false,
  message: null,
  status: "",
  token: null,
  isLoggedIn: false,
  isRecoveryEmailSent: null,
  isVerifyCode: null,
  id: "",
};

const adminReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case adminActionTypes.LOGIN_REQUEST:
      return Object.assign({
        ...state,
        isloading: true,
        isLoggedIn: false,
        status: "login request",
      });
    case adminActionTypes.LOGIN_SUCCESS:
      return Object.assign({
        ...state,
        isloading: false,
        admin: action.payload.admin,
        token: action.payload.token,
        status: "login success",
        isLoggedIn: true,
      });
    case adminActionTypes.LOGIN_FAILURE:
      return Object.assign({
        ...state,
        isloading: false,
        isLoggedIn: false,
        status: "admin login failure",
        message: action.payload.errorMessage,
      });
    case adminActionTypes.ADIMIN_EDIT_PROFILE_REQUEST:
      return Object.assign({
        ...state,
        isloading: true,
        status: "profile edit request",
      });
    case adminActionTypes.ADIMIN_EDIT_PROFILE_SUCCESS:
      return Object.assign({
        ...state,
        isloading: false,
        isLoggedIn: false,
        admin: null,
        status: "profile edit success",
      });
    case adminActionTypes.ADIMIN_EDIT_PROFILE_FAILURE:
      return Object.assign({
        ...state,
        isloading: false,
        status: "profile edit failure",
        message: action.payload,
      });
    case adminActionTypes.SEND_EMAIL_FOR_PASSWORD_RECOVERY_REQUEST:
      return Object.assign({
        ...state,
        admin: null,
        isloading: true,
        message: null,
        status: "",
        token: null,
        isLoggedIn: false,
        isRecoveryEmailSent: false,
        isVerifyCode: null,
      });
    case adminActionTypes.SEND_EMAIL_FOR_PASSWORD_RECOVERY_SUCCESS:
      return Object.assign({
        ...state,
        admin: null,
        isloading: false,
        message: action.payload.message,
        status: "",
        token: null,
        isLoggedIn: false,
        isRecoveryEmailSent: true,
        isVerifyCode: null,
      });
    case adminActionTypes.SEND_EMAIL_FOR_PASSWORD_RECOVERY_FAILURE:
      return Object.assign({
        ...state,
        admin: null,
        isloading: false,
        message: action.payload,
        status: "",
        token: null,
        isLoggedIn: false,
        isRecoveryEmailSent: false,
        isVerifyCode: null,
      });
    case adminActionTypes.VERIFY_SECURITY_CODE_REQUEST:
      return Object.assign({
        ...state,
        admin: null,
        isloading: true,
        message: null,
        status: "",
        token: null,
        isLoggedIn: false,
        isRecoveryEmailSent: true,
        isVerifyCode: false,
      });
    case adminActionTypes.VERIFY_SECURITY_CODE_SUCCESS:
      return Object.assign({
        ...state,
        admin: null,
        isloading: false,
        message: action.payload.message,
        status: "",
        token: null,
        isLoggedIn: false,
        isRecoveryEmailSent: false,
        isVerifyCode: true,
        id: action.payload.data.id,
      });
    case adminActionTypes.VERIFY_SECURITY_CODE_FAILURE:
      return Object.assign({
        ...state,
        admin: null,
        isloading: false,
        message: action.payload,
        status: "",
        token: null,
        isLoggedIn: false,
        isRecoveryEmailSent: true,
        isVerifyCode: false,
      });

    case adminActionTypes.RESET_PASSWORD_REQUEST:
      return Object.assign({
        ...state,
        admin: null,
        isloading: true,
        message: null,
        status: "",
        token: null,
        isLoggedIn: false,
        isRecoveryEmailSent: false,
        isVerifyCode: true,
      });
    case adminActionTypes.RESET_PASSWORD_SUCCESS:
      return Object.assign({
        ...state,
        admin: null,
        isloading: false,
        message: action.payload.message,
        status: "",
        token: null,
        isLoggedIn: false,
        isRecoveryEmailSent: false,
        isVerifyCode: false,
        id: "",
      });
    case adminActionTypes.RESET_PASSWORD_FAILURE:
      return Object.assign({
        ...state,
        admin: null,
        isloading: false,
        message: action.payload,
        status: "",
        token: null,
        isLoggedIn: false,
        isRecoveryEmailSent: false,
        isVerifyCode: false,
      });
    case adminActionTypes.LOGOUT:
      return Object.assign({
        ...INITIAL_STATE,
      });
    default:
      return state;
  }
};

export default adminReducer;
