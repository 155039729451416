import React, { Component } from "react";
import { css, StyleSheet } from "aphrodite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";

const styles = StyleSheet.create({
  btnWrapper: {
    backgroundColor: "#d8f0db",
    padding: "8px 0px 8px 0px",
    borderTopLeftRadius: 40,
    borderBottomLeftRadius: 40,
    borderTopRightRadius: 40,
    borderBottomRightRadius: 40,
    width: 75,
    color: "#3EB64A",
    textAlign: "center",
    textTransform: "capitalize",
    cursor: "pointer",
    fontSize: 13
  }
});

class GoBackBtn extends Component {
  render() {
    const { history } = this.props;
    return (
      <div className={css(styles.btnWrapper)} onClick={() => history.goBack()}>
        {" "}
        <FontAwesomeIcon icon={faChevronLeft} className="mr-1" size="sm" />
        go back
      </div>
    );
  }
}

export default withRouter(GoBackBtn);
