class Path {
  static BASE_URL = "https://api.emsk.us";

  static LOGIN = `${Path.BASE_URL}/login/admin`;
  static GET_USERS = `${Path.BASE_URL}/user`;
  static GET_ORDERS = `${Path.BASE_URL}/order`;
  static GET_MANAGERS = `${Path.BASE_URL}/branch-manager`;
  static GET_RESTAURANTS = `${Path.BASE_URL}/restaurant`;
  static GET_RESTAURANT_MENU = `${Path.BASE_URL}/dish`;
  static GET_RESTAURANT_OFFER = `${Path.BASE_URL}/offer`;
  static GET_RESTAURANT_GIFT_CARDS = `${Path.BASE_URL}/gift-card`;
  static GET_RESTAURANT_RESERVATIONS = `${Path.BASE_URL}/reservation/restaurent/populated-branch`;
  static GET_RESTAURANT_BRANCHES = `${Path.BASE_URL}/branch`;
  static EDIT_EMAIL = `${Path.BASE_URL}/admin`;
  static CHANGE_PASSWORD = `${Path.BASE_URL}/admin/changepassword`;
  static CHANGE_MANAGER_STATUS = `${Path.BASE_URL}/admin/restroadmin/manager/status`;
  static CHANGE_USER_STATUS = `${Path.BASE_URL}/admin/user/status`;
  static GET_RESTAURANTS_REWARDS = `${Path.BASE_URL}/reward`;
  static SEND_NOTIFICATION_TO_USER = `${Path.BASE_URL}/admin/notification/send`;
  static GET_USER_NOTIFICATION = `${Path.BASE_URL}/admin/notification/users`;
  static SEND_EMAIL_FOR_PASSWORD_RECOVERY = `${Path.BASE_URL}/recovery/email`;
  static VERIFY_SECURITY_CODE = `${Path.BASE_URL}/verify/code`;
  static RESET_PASSWORD = `${Path.BASE_URL}/reset/password`;
  static UPDATE_RESTAURANT = `${Path.BASE_URL}/admin/restaurant/status`;
  static PAYMENT_NOT_SUBMITTED = `${Path.BASE_URL}/admin/restroadmin`;
  static GET_RESTAURANTS_ADDONS = `${Path.BASE_URL}/addons`;
  static GET_ALL_NOTIFICATION = `${Path.BASE_URL}/admin/notification`;
  static DELETE_NOTIFICATION = `${Path.BASE_URL}/admin/notification`;
}

export default Path;
