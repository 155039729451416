import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import "./BranchesTab.css";
import { connect } from "react-redux";
import MapImage from "assets/img/map-bg.png";

const BranchesTab = props => {
  const { history, forwardPath, branches } = props;
  return (
    <Fragment>
      {branches &&
        branches.map(item => (
          <div
            className={"branch-card-container mt-3 mr-2"}
            onClick={() => history.push(forwardPath)}
          >
            <img src={MapImage} className="branch-card-head-img" alt="..." />
            <div className="branch-restaurant-info">
              <h4>{item.address.slice(0, item.address.indexOf(",") + 1)}</h4>
              <p className="text-center">
                {item.address.slice(item.address.indexOf(",") + 1)}
              </p>
            </div>
          </div>
        ))}
    </Fragment>
  );
};
const mapStateToProps = ({ restaurants: { branches } }) => ({ branches });

export default connect(mapStateToProps)(withRouter(BranchesTab));
