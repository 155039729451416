import React, { Fragment, Component } from "react";
import { Row } from "simple-flexbox";
import { connect } from "react-redux";
import { getrestaurantsMiddleware } from "./../../store/restaurants/restaurants.middleware";
import { StyleSheet, css } from "aphrodite/no-important";
import RestaurantCard from "components/RestaurantCard/RestaurantCard";
import SearchBox from "components/SeachBox/SearchBox";
import { searchBoxData } from "./restaurantCardData";
import Loader from "../../components/Loader/Loader";

const styles = StyleSheet.create({
  heading: {
    color: "#444444",
    fontWeight: "bold",
    marginBottom: "10px !important",
    textTransform: "uppercase",
    fontSize: 45
  },

  searchInput: {
    padding: "7px",
    inset: "none",
    borderStyle: "none",
    borderRadius: "20px"
  },

  searchContainer: {
    border: "solid 1px grey",
    textIndent: "20px",
    borderRadius: "20px",
    background: "white"
  },
  searchWrapper: {
    borderBottom: "2px solid lightgrey",
    paddingBottom: 20
  },
  ImageCardRow: {
    "@media (max-width: 768px)": {
      display: "flex !important",
      alignItems: "center !important",
      justifyContent: "center !important"
    }
  }
});
class Restaurants extends Component {
  componentDidMount() {
    const { token } = this.props;
    this.props.getRestaurants({ token });
  }
  searchRestaurant = event => {
    const query = event.target.value;
    const { token, getRestaurants } = this.props;
    if (query.length) {
      query.length >= 4 && getRestaurants({ token, query });
    } else {
      getRestaurants({ token });
    }
  };
  filterRestaurants = filter => {
    const { token, getRestaurants } = this.props;
    filter === "Show All"
      ? getRestaurants({ token })
      : getRestaurants({ token, filter: filter.toLowerCase() });
  };
  render() {
    const { restaurants } = this.props;
    return (
      <Fragment>
        <SearchBox
          onChange={this.searchRestaurant}
          filterFunction={this.filterRestaurants}
          heading={"Restaurants"}
          isSearchInput={true}
          searchBoxData={searchBoxData}
        />
        <Row wrap horizontal="start" className={css(styles.ImageCardRow)}>
          <RestaurantCard
            data={restaurants?.restaurants ?? []}
            forwardPath={"/restaurant/details"}
          />
        </Row>
        {!!restaurants.isloading && <Loader />}
      </Fragment>
    );
  }
}
const mapStateToProps = ({ restaurants, admin: { token } }) => ({
  restaurants,
  token
});
const mapDispatchToProps = dispatch => ({
  getRestaurants: data => dispatch(getrestaurantsMiddleware(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(Restaurants);
