import React from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import SidebarComponent from "./components/sidebar/SidebarComponent";
import HeaderComponent from "./components/header/HeaderComponent";
import { Switch, Route } from "react-router-dom";
import Login from "components/Login/Login";
import Restaurants from "screens/Restaurants/Restaurants";
import Orders from "screens/Orders/Orders";
import Users from "screens/Users/Users";
import Settings from "screens/Settings/Settings";
import UserInformation from "screens/UserInformation/UserInformation";
import Managers from "screens/Managers/Managers";
import OrderDetails from "screens/OrderDetails/OrderDetails";
import RestaurantDetails from "screens/RestaurantDetails/RestaurantDetails";
import ForgotPassword from "screens/ForgotPassword/ForgotPassword";
import CustomNotifications from "screens/CustomNotifications/CustomNotifications";
// import "./App.css";
import { connect } from "react-redux";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import AllNotifications from "screens/CustomNotifications/AllNotifications";
import BusinessInformation from "screens/BusinessInformation/BusinessInformation";
import OwnersOffice from "screens/OwnersOffice/OwnersOffice";
import BusinessEnvironment from "screens/BusinessEnvironment/BusinessEnvironment";

const styles = StyleSheet.create({
  container: {
    height: "100%",
    minHeight: "100vh",
  },
  content: {
    marginTop: 20,
    padding: 30,
  },
});

class App extends React.Component {
  state = { selectedItem: "Restaurants" };

  componentDidMount() {
    window.addEventListener("resize", this.resize);
  }

  UNSAFE_componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }
  // componentDidUpdate(prevProps) {

  // }
  // resize = () => this.forceUpdate();

  render() {
    const { selectedItem } = this.state;
    const { isLoggedIn } = this.props;
    return (
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route
          isLoggedIn={isLoggedIn}
          exact
          path="/forget-password"
          component={ForgotPassword}
        />
        <Route
          isLoggedIn={isLoggedIn}
          path={"/businessinformation"}
          component={BusinessInformation}
        />
        <Route
          isLoggedIn={isLoggedIn}
          path={"/ownersoffice"}
          component={OwnersOffice}
        />
        <Route
          isLoggedIn={isLoggedIn}
          path={"/businessenvironment"}
          component={BusinessEnvironment}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          exact
          path="/orders/details"
          component={OrderDetails}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          exact
          path="/users/information"
          component={UserInformation}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          exact
          path="/restaurant/details"
          component={RestaurantDetails}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          exact
          path="/allnotifications"
          component={AllNotifications}
        />
        <Row className={css(styles.container)}>
          <SidebarComponent
            selectedItem={selectedItem}
            onChange={(selectedItem) => this.setState({ selectedItem })}
          />
          <Column flexGrow={1}>
            <HeaderComponent title={selectedItem} />
            <div className={css(styles.content)}>
              <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path="/"
                component={Restaurants}
              />
              <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path="/orders"
                component={Orders}
              />
              <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path="/users"
                component={Users}
              />
              <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path="/managers"
                component={Managers}
              />
              <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path="/notifications"
                component={CustomNotifications}
              />
              <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path="/settings"
                component={Settings}
              />
            </div>
          </Column>
        </Row>
      </Switch>
      // <BrowserRouter>
      // </BrowserRouter>
    );
  }
}
const mapStateToProps = ({ admin: { isLoggedIn } }) => ({ isLoggedIn });
export default connect(mapStateToProps)(App);
