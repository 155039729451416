import React, { Component } from "react";
import HeaderComponent from "components/header/HeaderComponent";
import { Column } from "simple-flexbox";
import InformationBanner from "components/InformationBanner/InformationBanner";
import OrdersList from "components/OrdersList/OrdersList";
import { connect } from "react-redux";

class OrderDetails extends Component {

  componentDidMount() {
    const { orderDetail } = this.props;
    if (!orderDetail.hasOwnProperty("_id")) {
      return this.props.history.push("/orders");
    }
  }

  render() {
    const { orderDetail } = this.props;
    return (
      <div className="flexer">
        <Column style={{ width: "80%" }}>
          <HeaderComponent isBackBtn={true} />
          <InformationBanner data={orderDetail} isOrderPage={true} />
          <OrdersList
            data={orderDetail.items}
            isHeaderAllowed={true}
            tableHeading={"Order Details"}
            isOrderDetailPage={true}
          />
        </Column>
      </div>
    );
  }
}
const mapStateToProps = ({ orders: { orderDetail } }) => ({ orderDetail });
export default connect(mapStateToProps)(OrderDetails);
