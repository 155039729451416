import { restuarantsActionsTypes } from "./restaurants.action.types";

export const getrestaurantsRequest = () => ({
  type: restuarantsActionsTypes.GET_RESTAURANTS_REQUEST
});

export const getrestaurantsSuccess = restaurants => ({
  type: restuarantsActionsTypes.GET_RESTAURANTS_SUCCESS,
  payload: restaurants
});

export const getrestaurantsFailure = error => ({
  type: restuarantsActionsTypes.GET_RESTAURANTS_FAILURE,
  payload: error
});

export const getrestaurantDetails = restaurantId => ({
  type: restuarantsActionsTypes.GET_RESTAURANTS_DETAILS,
  payload: restaurantId
});

export const getrestaurantMenuRequest = () => ({
  type: restuarantsActionsTypes.GET_RESTAURANT_MENU_REQUEST
});

export const getrestaurantMenuSuccess = menu => ({
  type: restuarantsActionsTypes.GET_RESTAURANT_MENU_SUCCESS,
  payload: menu
});

export const getrestaurantMenuFailure = error => ({
  type: restuarantsActionsTypes.GET_RESTAURANT_MENU_FAILURE,
  payload: error
});

export const getrestaurantOfferRequest = () => ({
  type: restuarantsActionsTypes.GET_RESTAURANT_OFFER_REQUEST
});

export const getrestaurantOfferSuccess = menu => ({
  type: restuarantsActionsTypes.GET_RESTAURANT_OFFER_SUCCESS,
  payload: menu
});

export const getrestaurantOfferFailure = error => ({
  type: restuarantsActionsTypes.GET_RESTAURANT_OFFER_FAILURE,
  payload: error
});

export const getrestaurantGiftCardsRequest = () => ({
  type: restuarantsActionsTypes.GET_RESTAURANT_GIFT_CARDS_REQUEST
});

export const getrestaurantGiftCardsSuccess = menu => ({
  type: restuarantsActionsTypes.GET_RESTAURANT_GIFT_CARDS_SUCCESS,
  payload: menu
});

export const getrestaurantGiftCardsFailure = error => ({
  type: restuarantsActionsTypes.GET_RESTAURANT_GIFT_CARDS_FAILURE,
  payload: error
});

export const getrestaurantReservationsRequest = () => ({
  type: restuarantsActionsTypes.GET_RESTAURANT_RESERVATIONS_REQUEST
});

export const getrestaurantReservationsSuccess = reservations => ({
  type: restuarantsActionsTypes.GET_RESTAURANT_RESERVATIONS_SUCCESS,
  payload: reservations
});

export const getrestaurantReservationsFailure = error => ({
  type: restuarantsActionsTypes.GET_RESTAURANT_RESERVATIONS_FAILURE,
  payload: error
});

export const getrestaurantBranchesRequest = () => ({
  type: restuarantsActionsTypes.GET_RESTAURANT_BRANCHES_REQUEST
});

export const getrestaurantBranchesSuccess = branches => ({
  type: restuarantsActionsTypes.GET_RESTAURANT_BRANCHES_SUCCESS,
  payload: branches
});

export const getrestaurantBranchesFailure = error => ({
  type: restuarantsActionsTypes.GET_RESTAURANT_BRANCHES_FAILURE,
  payload: error
});

export const getrestaurantRewardsRequest = () => ({
  type: restuarantsActionsTypes.GET_RESTAURANT_REWARDS_REQUEST
});

export const getrestaurantRewardsSuccess = restaurantId => ({
  type: restuarantsActionsTypes.GET_RESTAURANT_REWARDS_SUCCESS,
  payload: restaurantId
});

export const getrestaurantRewardsFailure = error => ({
  type: restuarantsActionsTypes.GET_RESTAURANT_REWARDS_FAILURE,
  payload: error
});

export const getrestaurantBranchesForLocationSuceess = branches => ({
  type: restuarantsActionsTypes.GET_RESTAURANT_BRANCHES_FOR_LOCATION_SUCCESS,
  payload: branches
});

export const updateRestaurantRequest = () => ({
  type: restuarantsActionsTypes.UPDATE_RESTAURANT_REQUEST
});

export const updateRestaurantSuccess = restaurant => ({
  type: restuarantsActionsTypes.UPDATE_RESTAURANT_SUCCESS,
  payload: restaurant
});

export const updateRestaurantFailure = error => ({
  type: restuarantsActionsTypes.UPDATE_RESTAURANT_FAILURE,
  payload: error
});


export const getrestaurantAddonsRequest = () => ({
  type: restuarantsActionsTypes.GET_RESTAURANT_ADDONS_REQUEST
});

export const getrestaurantAddonsSuccess = restaurantId => ({
  type: restuarantsActionsTypes.GET_RESTAURANT_ADDONS_SUCCESS,
  payload: restaurantId
});

export const getrestaurantAddonsFailure = error => ({
  type: restuarantsActionsTypes.GET_RESTAURANT_ADDONS_FAILURE,
  payload: error
});