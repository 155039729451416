import React, { Component, Fragment } from "react";
import { StyleSheet, css } from "aphrodite/no-important";
import { Row, Column } from "simple-flexbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faUser } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { editProfileMiddleware } from "store/admin/admin.middleware";
import { Modal } from "antd";
import Loader from "components/Loader/Loader";

const styles = StyleSheet.create({
  settingsContainer: {
    width: 450,
    backgroundColor: "white",
    paddingTop: 20,
    paddingBottom: 20,
    transition: "all ease 1s",
    boxShadow: "0 0 10px lightgrey",

    "@media (max-width: 768px)": {
      width: "100%"
    }
  },

  avatarImage: {
    height: 100,
    width: 100,
    marginTop: -70,
    borderRadius: "50%",
    transition: "all ease 1s"
  },

  borderLine: {
    border: "solid 1px lightgrey",
    width: "100%",
    marginTop: 10
  },
  customAvatar: {
    height: 100,
    width: 100,
    borderRadius: "50%",
    backgroundColor: "#3EB64A",
    marginTop: -70,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  inputField: {
    marginBottom: "20px",
    borderRadius: "20px",
    padding: "7px",
    inset: "none",
    borderStyle: "solid",
    borderColor: "#c0c0c0",
    textIndent: "20px",
    width: "75%",
    marginTop: 5
  }
});

class Settings extends Component {
  state = {
    isEmailEditing: false,
    email: "",
    isPasswordEditing: false,
    oldPassword: "",
    newPassword: "",
    isSubmitted: false
  };

  handleChange = event =>
    this.setState({ [event.target.name]: event.target.value });
  handleSubmit = () => {
    this.setState({
      isSubmitted: true
    });
    const {
      isEmailEditing,
      email,
      isPasswordEditing,
      oldPassword,
      newPassword
    } = this.state;
    const {
      editProfile,
      admin: { token }
    } = this.props;
    if (isEmailEditing && email) {
      editProfile({
        type: "email",
        token,
        email
      });
      return;
    }
    if (isPasswordEditing && oldPassword && newPassword) {
      editProfile({
        type: "password",
        token,
        currentPassword: oldPassword,
        newPassword
      });
      return;
    }
  };
  componentDidUpdate(prevProps) {
    const { admin, history } = this.props;
    if (prevProps.admin !== admin) {
      if (admin.status === "profile edit success") {
        history.push("/");
      }
    }
  }

  countDown = () => {
    let secondsToGo = 3;
    const modal = Modal.success({
      title: "This is a notification message",
      content: `This modal will be destroyed after ${secondsToGo} second.`
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
      modal.update({
        content: `This modal will be destroyed after ${secondsToGo} second.`
      });
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      modal.destroy();
    }, secondsToGo * 1000);
  };
  render() {
    const {
      isEmailEditing,
      email,
      isPasswordEditing,
      oldPassword,
      newPassword
    } = this.state;
    const { admin } = this.props;
    return (
      <Row horizontal="center" vertical="center" className="mt-4 vh-50">
        <Column
          horizontal="center"
          vertical="center"
          className={css(styles.settingsContainer)}
        >
          {/* <img
            src={settingsAvatar}
            alt="avatar"
            className={css(styles.avatarImage)}
          /> */}
          <div className={css(styles.customAvatar)}>
            <FontAwesomeIcon icon={faUser} size="3x" color="white" />
          </div>
          <h4 className="p-0 mt-2 mb-0 font-weight-bold ">
            Name: {admin.admin?.fullName}
          </h4>
          <p className="text-muted">Email Address: {admin.admin?.email}</p>

          <div
            className="flexer-column w-75 mt-5"
            onClick={() =>
              this.setState(prevState => ({
                isEmailEditing: !prevState.isEmailEditing,
                isPasswordEditing: false
              }))
            }
            style={{ cursor: "pointer" }}
          >
            <div className="flexer-space-between w-100">
              <span>Change Email</span>
              <FontAwesomeIcon icon={faChevronRight} color="#3EB64A" />
            </div>
            <hr className={css(styles.borderLine)} />
          </div>

          {isEmailEditing && (
            <input
              type="email"
              name="email"
              value={email}
              placeholder="Enter new email"
              className={css(styles.inputField)}
              onChange={this.handleChange}
            />
          )}

          <div
            className="flexer-column w-75 mt-3"
            onClick={() =>
              this.setState(prevState => ({
                isPasswordEditing: !prevState.isPasswordEditing,
                isEmailEditing: false
              }))
            }
            style={{ cursor: "pointer" }}
          >
            <div className="flexer-space-between w-100">
              <span>Change Password</span>
              <FontAwesomeIcon icon={faChevronRight} color="#3EB64A" />
            </div>
            <hr className={css(styles.borderLine)} />
          </div>

          {isPasswordEditing && (
            <Fragment>
              <input
                type="password"
                name="oldPassword"
                value={oldPassword}
                placeholder="Enter old password"
                className={css(styles.inputField)}
                onChange={this.handleChange}
              />
              <input
                type="password"
                name="newPassword"
                value={newPassword}
                placeholder="Enter new password"
                className={css(styles.inputField)}
                onChange={this.handleChange}
              />
            </Fragment>
          )}
          <div className="flexer-space-between w-75 mt-2 mb-4">
            <button disabled
              onClick={this.handleSubmit}
              // type="submit"
              className="w-50 login-btn mt-5"
            >
              Update
            </button>
          </div>

        </Column>
        {!!admin.isloading && <Loader />}
      </Row>
    );
  }
}
const mapStateToProps = ({ admin }) => ({ admin });
const mapDispatchToProps = dispatch => ({
  editProfile: data => dispatch(editProfileMiddleware(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(Settings);
