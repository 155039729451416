import React, { Fragment, useEffect } from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite/no-important";
import { connect } from "react-redux";
import "./CustomNotifications.css";
import Loader from "components/Loader/Loader";
import GoBackBtn from "components/GoBackBtn/GoBackBtn";
import deleteIcon from "assets/img/delete-icon.png";
// import { Menu } from "antd";
import { getAllNotficationsMiddleware } from "store/notifications/notification.middleware";
import Axios from "axios";
import Path from "Config/Path";
import { Modal } from "antd";

const styles = StyleSheet.create({

    notificationWrapper: {
        backgroundColor: "white",
        borderRadius: 5,
        padding: 25,
        boxShadow: "0 0 10px -3px grey",
        width: "55%",
        "@media (max-width: 768px)": {
            width: "100%",
            overflow: "hidden",
            boxShadow: "none",
            padding: 5
        }
    },
    notificationHeader: {
        height: 120,
        padding: "15px 10px 10px 10px",
        width: "100%",
        marginBottom: "10px",
        borderBottom: "solid 1px lightgrey"
    },
    fontBolder: {
        fontWeight: 900,
        paddingTop: 10
    },
    fontLink: {
        fontWeight: "bold",
        paddingTop: 15,
        marginLeft: "20px",
        color: 'rgb(62, 182, 74) !important',
        ":hover": {
            textDecoration: 'underline'
        },
        cursor: 'pointer'
    },
    Dropdown: {
        padding: 10,
        width: "45%",
        borderRadius: 5,
        textIndent: 10,
        marginTop: 13,
        border: "1px solid lightgrey"
    },

    iconWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 17,
        width: 17,
        borderRadius: "50%",
        backgroundColor: "white",
        border: "1px solid rgb(62, 182, 74)"
    },
    textMuted: {
        color: "#AFAFAF"
    }
});

const AllNotifications = ({ history, token, getNotifications, scheduledNotifications, isloading }) => {
    useEffect(() => {
        getNotifications({ token });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const onClick = ({ key }) => {
        history.push("/notifications");
    };
    // const menu = (
    //     <Menu onClick={onClick}>
    //         <Menu.Item>
    //             <p>Edit Notification</p>
    //         </Menu.Item>
    //     </Menu>
    // );
    const countDown = () => {
        let secondsToGo = 5;
        const modal = Modal.success({
            title: "Delete Notification",
            content: "Notification deleted."
        });
        const timer = setInterval(() => {
            secondsToGo -= 1;
        }, 1000);
        setTimeout(() => {
            clearInterval(timer);
            modal.destroy();

        }, secondsToGo * 1000);
    }
    const deleteNotification = async (not) => {
        const data = {
            notification: not._id
        }
        try {
            const response = await Axios.delete(`${Path.DELETE_NOTIFICATION}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
                data,
            })
            if (response.data.status === 'success') {
                getNotifications({ token });
                countDown()
            }
        } catch (error) {

        }
    }
    return (
        <Fragment>
            <Row className={css(styles.notificationHeader)}
                justifyContent="space-between"
            >
                <Column alignSelf="start">
                    <GoBackBtn />
                </Column>
                <Column alignSelf="end">
                    <h4 className={css(styles.fontBolder)}>All Notifications</h4>
                </Column>
                <Column alignSelf="start"><div></div></Column>
            </Row>
            {scheduledNotifications?.length ? scheduledNotifications.map(not =>
                (<Row key={not._id} className="flexer" style={{ marginTop: "2%", minHeight: "10%" }}> <Column
                    horizontal="center"
                    vertical="center"
                    className={css(styles.notificationWrapper)}
                >
                    <div className="flexer-space-between w-100">
                        <h4 style={{ fontWeight: "bolder" }}>{not?.filters?.notification.title}</h4>
                        <div className="flexer-space-between" style={{ width: "50px" }}>
                            {/* <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft">
                                <div className={css(styles.iconWrapper)}>
                                    <FontAwesomeIcon
                                        icon={faChevronDown}
                                        color="rgb(62, 182, 74)"
                                        size="sm"
                                    />
                                </div>
                            </Dropdown> */}
                            <div style={{ cursor: 'pointer' }} onClick={() => deleteNotification(not)}>
                                <img
                                    alt="avatar"
                                    src={deleteIcon}
                                    height="25"
                                    width="20"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-100 mt-2">
                        <p className={css(styles.textMuted)}>{not?.filters?.notification.body}</p>
                    </div>
                </Column>
                </Row>)) : (<Row className="flexer" style={{ marginTop: "2%", minHeight: "10%" }}>
                    <h4 onClick={onClick} className={css(styles.fontLink)}> No notifiactions scheduled, Schedule Now</h4>
                </Row>)}
            {isloading && <Loader />}

        </Fragment>
    );
}
const mapStateToProps = ({
    admin: { token },
    notification: { scheduledNotifications, isloading }
}) => ({
    token,
    scheduledNotifications,
    isloading
});

const mapDispatchToProps = dispatch => ({
    getNotifications: data => dispatch(getAllNotficationsMiddleware(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllNotifications);
