import axios from "axios";
import Path from "Config/Path";
import {
  getUsersSuccess,
  getUsersRequest,
  getUsersFailure,
  changeUserStatusRequest,
  changeUserStatusFailure,
  changeUserStatusSuccess,
} from "./users.actions";

// Authorization: `Bearer ${token}`,
// const config = {
//     headers: { 'Content-Type': 'application/json' }
// };
export const getUsersMiddleware = (data) => {
  const params = {};
  data.query && (params.fullName = data.query);
  return async (dispatch) => {
    try {
      dispatch(getUsersRequest());
      const response = await axios.get(Path.GET_USERS, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.token}`,
        },
        params,
      });
      dispatch(getUsersSuccess(response.data.User));
    } catch (error) {
      dispatch(
        getUsersFailure({
          success: false,
          errorMessage: "Something went wrong, please try again.",
        })
      );
    }
  };
};

export const changeUserStatusMiddleware = (data) => {
  return async (dispatch) => {
    try {
      dispatch(changeUserStatusRequest());
      await axios.put(
        Path.CHANGE_USER_STATUS,
        { user: data.userId, status: data.status },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      dispatch(changeUserStatusSuccess());
    } catch (error) {
      dispatch(
        changeUserStatusFailure({
          success: false,
          errorMessage: "Something went wrong please try again",
        })
      );
    }
  };
};
