import React, { Component } from "react";
import { connect } from "react-redux";
import HeaderComponent from "components/header/HeaderComponent";
import { Column, Row } from "simple-flexbox";
import { css, StyleSheet } from "aphrodite";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TabBar from "components/TabBar/TabBar";
import { tabScreenData } from "./TabScreenData";
import { updateRestaurantMiddleware } from "store/restaurants/restaurants.middleware";
import Axios from "axios";
import Path from "Config/Path";
import { Modal } from "antd";

const styles = StyleSheet.create({
  restaurantName: {
    fontWeight: 800,
    height: 10
  },
  PaymentBadge: {
    border: "1px solid #FFC247",
    padding: 4,
    borderRadius: 5,
    color: "#FFC247",
    backgroundColor: "#fdf6e4",
    boxShadow: "0 0 10px -3px grey",
    // marginRight: 20,
    cursor: 'pointer',
    textAlign: "center",
    margin: 0
  },
  wrapper: {
    borderBottom: "1px solid lightgrey",
    paddingBottom: 20,
    marginTop: 5
  },
  description: {
    color: "#b2b2b2",
    fontWeight: 500,
    letterSpacing: 0.3
  }
});

class RestaurantDetails extends Component {
  state = {
    restaurantStatus: this.props.restaurantDetail.status
  };

  changeRestaurantStatus = restaurantStatus => {
    const { token, restaurantDetail } = this.props;
    const status = restaurantStatus === "approved" ? "blocked" : "approved";

    this.setState({
      restaurantStatus: restaurantStatus === "approved" ? "blocked" : "approved"
    });
    this.props.updateRestaurant({
      token,
      status,
      restaurant: restaurantDetail._id
    });
  };
  countDown = () => {
    let secondsToGo = 5;
    const modal = Modal.success({
      title: "Payment",
      content: "Payment Status updated"
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      modal.destroy();
    }, secondsToGo * 1000);
  };
  handlepaymentReminder = async () => {
    const { token, restaurantDetail: { owner } } = this.props;
    const data = {
      restaurantOwner: owner._id,
      isSubsFeePaid: !owner.isSubsFeePaid
    }
    try {
      const response = await Axios.put(`${Path.PAYMENT_NOT_SUBMITTED}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      if (response.data.status === 'success') {
        this.countDown()
      }
    } catch (error) {

    }
  }
  componentDidMount() {
    const { restaurantDetail } = this.props;
    if (!restaurantDetail.hasOwnProperty("_id")) {
      return this.props.history.push("/");
    }
  }

  render() {
    const { restaurantDetail } = this.props;
    const { restaurantStatus } = this.state;
    return (
      <div className="flexer">
        <Column style={{ width: "80%" }}>
          <HeaderComponent isBackBtn={true} />
          <div className="container-fluid" style={{ marginTop: "60px" }}>
            <div className="row">
              <div className="col-lg-1 col-md-2 col-sm-4 m-0 p-0">
                <img
                  src={restaurantDetail.image}
                  className="img-fluid"
                  alt="roll"
                />
              </div>
              <div className="col-lg-11 col-md-10 col-sm-8">
                <Row
                  justifyContent="space-between"
                  className={css(styles.wrapper)}
                >
                  <Column justifyContent="space-between">
                    <h4 className={css(styles.restaurantName)}>
                      {restaurantDetail.name}
                    </h4>
                    <p className="text-muted mt-3" style={{ maxWidth: 500 }}>
                      {restaurantDetail.address}
                    </p>
                  </Column>
                  <div className="flexer" >
                      <p className={css(styles.PaymentBadge)} onClick={this.handlepaymentReminder}>
                       {restaurantDetail.owner?.isSubsFeePaid ? "Payment not submitted" : "Payment Submitted"}
                      </p>
                  
                    <Row
                      vertical="center"
                      className="ml-3"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        this.changeRestaurantStatus(restaurantDetail.status)
                      }
                    >
                      <FontAwesomeIcon
                        className="mr-1"
                        icon={faBan}
                        color="#a8a8a8"
                      />
                      <span
                        className="text-muted"
                        style={{ textTransform: "capitalize" }}
                      >
                        {restaurantStatus === "approved"
                          ? "block"
                          : "approve"}
                        {/* {!menuItemActive ? 'Approve' : 'Block'} */}
                      </span>
                    </Row>
                  </div>
                </Row>
                <Row className="mt-4">
                  <p className={css(styles.description)}>
                    {restaurantDetail.description}
                  </p>
                </Row>
              </div>
            </div>
          </div>
          <TabBar
            tabScreenData={tabScreenData}
            restaurantTabBar={true}
            restaurantId={restaurantDetail._id}
            ownerId={restaurantDetail.owner && restaurantDetail.owner._id}
          />
        </Column>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateRestaurant: data => dispatch(updateRestaurantMiddleware(data))
});

const mapStateToProps = ({
  admin: { token },
  restaurants: { restaurantDetail },
  globalReducer: { menuItemActive }
}) => ({ restaurantDetail, menuItemActive, token });
export default connect(mapStateToProps, mapDispatchToProps)(RestaurantDetails);
