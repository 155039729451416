import ActionTypes from "./ActionTypes";

class GlobalActions {
  static isMenuItemActive = payload => {
    return {
      type: ActionTypes.MENU_ITEM,
      payload
    };
  };
}

export default GlobalActions;
