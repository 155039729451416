import ActionTypes from "../actions/ActionTypes";

const initialState = {
  menuItemActive: true
};
export const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.MENU_ITEM:
      return {
        ...state,
        menuItemActive: action.payload
      };

    default:
      return state;
  }
};
