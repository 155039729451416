import { userActionsTypes } from "./users.action.types";

export const getUsersRequest = () => ({
  type: userActionsTypes.GET_USERS_REQUEST
});

export const getUsersSuccess = users => ({
  type: userActionsTypes.GET_USERS_SUCCESS,
  payload: users
});

export const getUsersFailure = error => ({
  type: userActionsTypes.GET_USERS_FAILURE,
  payload: error
});

export const changeUserStatusRequest = () => ({
  type: userActionsTypes.CHANGE_USER_STATUS_REQUEST
});

export const changeUserStatusSuccess = () => ({
  type: userActionsTypes.CHANGE_USER_STATUS_SUCCESS
});

export const changeUserStatusFailure = error => ({
  type: userActionsTypes.CHANGE_USER_STATUS_FAILURE,
  payload: error
});
