import React, { Component, Fragment } from "react";
import "./TabBar.css";
import OrdersList from "components/OrdersList/OrdersList";
import { orderDetails } from "./orderDetails";
import { restaurantCardData } from "./restaurantCardData";
import { offersAndPromotionsData } from "./OffersAndPromotionsTab/offersAndPromotionsData";
import { restaurantReservationData } from "./ReservationsTab/ReservationsData";
import { branchesData } from "./BranchesTab/BranchesData";
import GlobalAction from "store/actions/globalAction";
import { connect } from "react-redux";

import RestaurantCard from "components/RestaurantCard/RestaurantCard";
import { Row } from "simple-flexbox";
import MenuTab from "components/TabBar/MenuTab/MenuTab";
import GiftCardsTab from "components/TabBar/GiftCardsTab/GiftCardsTab";
import OffersAndPromotionTab from "components/TabBar/OffersAndPromotionsTab/OffersAndPromotionTab";
import ReservationTab from "components/TabBar/ReservationsTab/ReservationsTab";
import BranchesTab from "./BranchesTab/BranchesTab";
import AddOnsTab from './AddOnsTab/AddOnsTab'
import {
  getrestaurantMenuMiddleware,
  getrestaurantGiftCardsMiddleware,
  getrestaurantofferMiddleware,
  getrestaurantReservationsMiddleware,
  getrestaurantBranchessMiddleware,
  getrestaurantsMiddleware,
  getrestaurantAddOnsMiddleware
} from "store/restaurants/restaurants.middleware";
import Loader from "components/Loader/Loader";
class TabBar extends Component {
  state = {
    tabScreenData: []
  };

  componentDidMount() {
    this.setState(
      { tabScreenData: this.props.tabScreenData },
      this.getActiveTabData
    );
  }

  setItem = (index, tabName) => {
    const merged = this.state.tabScreenData;
    if (tabName === "Menu") {
      this.props.isMenuItemActive(true);
    } else {
      this.props.isMenuItemActive(false);
    }
    if (!merged[index].isActive) {
      merged[index].isActive = !this.state.tabScreenData[index].isActive;
    }
    merged.forEach((item, rowIndex) => {
      return index !== rowIndex ? (item.isActive = false) : null;
    });
    this.setState({ tabScreenData: merged }, this.getActiveTabData);
  };
  getActiveTabData = index => {
    const { tabScreenData } = this.state;
    const {
      token,
      restaurantId,
      getMenuItems,
      getOffers,
      getGiftCards,
      getReservations,
      getBranches,
      getAddOns,
      getOtherRestaurants,
      ownerId
    } = this.props;
    // const activeTab = tabScreenData[index]
    const activeTab = tabScreenData.find(tab => tab.isActive === true);
    switch (activeTab.tabName) {
      case "Menu":
        getMenuItems({ token, restaurantId });
        return;
      case "Offers & Promotion":
        getOffers({ token, restaurantId });
        return;
      case "Gift Cards":
        getGiftCards({ token, restaurantId });

        return;
      case "Reservations":
        getReservations({ token, restaurantId });
        return;
      case "Other Restaurants":
        getOtherRestaurants({ token, ownerId });
        return;
      case "Branches":
        getBranches({ token, restaurantId });
        return;
      case "Add-Ons":
        getAddOns({ token, restaurantId });
        return;
      default:
        return null;
    }
  };
  render() {
    const { tabScreenData } = this.state;
    const { restaurantTabBar, restaurants, isloading } = this.props;
    return (
      <div className="flexer-column container-fluid w-100 p-0">
        {restaurantTabBar ? (
          <div className="row w-100 tabbar-wrapper">
            {tabScreenData.map((item, index) => (
              <div
                style={{
                  color: item.isActive ? "white" : "#3EB64A",
                  cursor: "pointer",
                  paddingTop:"24px",
                  paddingBottom:"24px",
                  paddingLeft:"16px",
                  paddingRight:"16px"
                }}
                className={`${item.isActive &&
                  "active"} col-md-12 col-sm-12 flexer tabbar-mx-w`}
                onClick={() => this.setItem(index, item.tabName)}
              >
                {item.tabName}
              </div>
            ))}
          </div>
        ) : (
          <div className="row w-100 tabbar-wrapper">
            {tabScreenData.map((item, index) => (
              <div
                style={{
                  color: item.isActive ? "white" : "#3EB64A",
                  cursor: "pointer"
                }}
                className={`${item.isActive &&
                  "active"} col-lg-6 col-md-12 col-sm-12 p-4 flexer tabbar-mx-w`}
                onClick={() => this.setItem(index)}
              >
                {item.tabName}
              </div>
            ))}
          </div>
        )}

        <div className="w-100 mb-5">
          {tabScreenData.map((item, index) => {
            const conditional = item.isActive && item.tabName;
            return (
              <Fragment key={index}>
                {/* Main tab screen items to show for a restaurant */}
                {conditional === "Menu" && (
                  <Row wrap className="flexer mt-3 mb-5">
                    <MenuTab />
                  </Row>
                )}

                {conditional === "Gift Cards" && (
                  <Row wrap className="flexer mt-3 mb-5">
                    <GiftCardsTab />
                  </Row>
                )}

                {conditional === "Other Restaurants" && (
                  <Row wrap className="mt-3 mb-5">
                    <RestaurantCard data={restaurants} />
                  </Row>
                )}

                {conditional === "Offers & Promotion" && (
                  <Row wrap className="flexer-space-between mt-3 mb-5">
                    <OffersAndPromotionTab data={offersAndPromotionsData} />
                  </Row>
                )}

                {conditional === "Reservations" && (
                  <Row wrap className="flexer mt-3 mb-5">
                    <ReservationTab data={restaurantReservationData} />
                  </Row>
                )}

                {conditional === "Branches" && (
                  <Row wrap className="mt-3 mb-5">
                    <BranchesTab data={branchesData} />
                  </Row>
                )}

                {/* Two screens for User details screen */}
                {conditional === "Recent Orders" && (
                  <Fragment>
                    <OrdersList
                      data={orderDetails}
                      isOrderPage={true}
                      isUserOrders={true}
                    />
                  </Fragment>
                )}

                {conditional === "Favorites Restaurants" && (
                  <Row wrap className=" mt-3 mb-5 flexer-space-between">
                    <RestaurantCard
                      data={restaurantCardData}
                      isUserFvrtRestaurants={true}
                    />
                  </Row>
                )}
                {conditional === "Add-Ons" && (
                  <Row wrap className="mt-3 mb-5 mr-3">
                    <AddOnsTab />
                  </Row>
                )}
              </Fragment>
            );
          })}
        </div>
        {!!isloading && <Loader />}
      </div>
    );
  }
}
const mapStateToProps = ({
  admin: { token },
  restaurants: { restaurants, isloading }
}) => ({ token, restaurants, isloading });
const mapDispatchToProps = dispatch => ({
  isMenuItemActive: data => dispatch(GlobalAction.isMenuItemActive(data)),
  getMenuItems: data => dispatch(getrestaurantMenuMiddleware(data)),
  getOffers: data => dispatch(getrestaurantofferMiddleware(data)),
  getGiftCards: data => dispatch(getrestaurantGiftCardsMiddleware(data)),
  getReservations: data => dispatch(getrestaurantReservationsMiddleware(data)),
  getBranches: data => dispatch(getrestaurantBranchessMiddleware(data)),
  getOtherRestaurants: data => dispatch(getrestaurantsMiddleware(data)),
  getAddOns:data=> dispatch(getrestaurantAddOnsMiddleware(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(TabBar);
