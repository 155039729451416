import React from "react";
import { bool, func, string } from "prop-types";
import { Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
  activeBar: {
    height: 56,
    width: 5,
    backgroundColor: "white",
    position: "absolute",
    left: 0
  },

  activeContainer: {
    backgroundColor: "#009F10"
  },
  activeTitle: {
    color: "white"
  },
  container: {
    height: 56,
    cursor: "pointer",

    // ":hover": {
    //   backgroundColor: "#009F10",
    //   opacity: 0.
    // },
    paddingLeft: 32,
    paddingRight: 10
  },
  title: {
    fontSize: 15,
    lineHeight: "20px",
    letterSpacing: "0.2px",
    color: "#FFFFFF",
    marginLeft: 15
  }
});

function MenuItemComponent(props) {
  const { active, icon, title, ...otherProps } = props;
  const Icon = icon;
  return (
    <Row
      className={css(styles.container, active && styles.activeContainer)}
      vertical="center"
      {...otherProps}
    >
      {active && <div className={css(styles.activeBar)}></div>}
      {/* <Icon fill={active ? "white" : "#444444"} /> */}
      <img
        src={Icon}
        height="35"
        alt={title}
        style={{ opacity: active ? 1 : 0.6 }}
      />
      <span
        className={css(styles.title, active && styles.activeTitle)}
        style={{ opacity: active ? 1 : 0.6 }}
      >
        {title}
      </span>
    </Row>
  );
}

MenuItemComponent.propTypes = {
  active: bool,
  icon: func,
  title: string
};

export default MenuItemComponent;
