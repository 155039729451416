import React from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import LogoComponent from "./LogoComponent";
import MenuItemComponent from "./MenuItemComponent";
import IconBurger from "../../assets/icon-burger";
import { withRouter } from "react-router-dom";
import RestaurantIcon from "assets/img/RestaurantIcon.png";
import UserIcon from "assets/img/UserIcon.png";
import ManagerIcon from "assets/img/ManagerIcon.png";
import SettingIcon from "assets/img/SettingIcon.png";
import Notification from "assets/img/notification.png";
import { connect } from "react-redux";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { logout } from "store/admin/admin.actions";

const { confirm } = Modal;

const styles = StyleSheet.create({
  burgerIcon: {
    cursor: "pointer",
    position: "absolute",
    left: 24,
    top: 34
  },
  container: {
    backgroundColor: "#3EB64A",
    boxShadow: "10px 0 10px lightgrey",
    width: 270,
    height: "100%"
  },
  containerMobile: {
    transition: "left 0.5s, right 0.5s",
    position: "absolute",
    width: 255,
    height: "100%",
    zIndex: 901
  },
  mainContainer: {
    height: "100%",
    minHeight: "100vh"
  },
  mainContainerMobile: {
    position: "absolute",
    top: 0,
    left: 0
  },
  mainContainerExpanded: {
    width: "100%",
    minWidth: "100vh"
  },
  outsideLayer: {
    position: "absolute",
    width: "100vw",
    // minWidth: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,.50)",
    zIndex: 900,
    border: "1px solid red"
  },
  separator: {
    borderTop: "1px solid #DFE0EB",
    marginTop: 16,
    marginBottom: 16,
    opacity: 0.06
  },
  hide: {
    left: -255
  },
  show: {
    left: 0
  }
});

class SidebarComponent extends React.Component {
  state = { expanded: false };

  onItemClicked = item => {
    this.setState({ expanded: false }, () => {
      return this.props.history.push(item);
    });
  };

  isMobile = () => window.innerWidth <= 768;

  toggleMenu = () =>
    this.setState(prevState => ({ expanded: !prevState.expanded }));

  renderBurger = () => {
    return (
      <div onClick={this.toggleMenu} className={css(styles.burgerIcon)}>
        <IconBurger />
      </div>
    );
  };

  showLogoutConfirm = logout => {
    confirm({
      title: `Logout`,
      icon: <ExclamationCircleOutlined color="green" />,
      content: `Are you sure you want to logout?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        logout();
      }
      // onCancel() {
      // },
    });
  };
  render() {
    const { expanded } = this.state;
    const isMobile = this.isMobile();
    const { logout } = this.props;
    return (
      <div style={{ position: "relative" }}>
        <Row
          className={css(styles.mainContainer)}
          breakpoints={{
            768: css(
              styles.mainContainerMobile,
              expanded && styles.mainContainerExpanded
            )
          }}
        >
          {isMobile && !expanded && this.renderBurger()}
          <Column
            className={css(styles.container)}
            breakpoints={{
              768: css(
                styles.containerMobile,
                expanded ? styles.show : styles.hide
              )
            }}
          >
            <LogoComponent />
            <Column>
              <MenuItemComponent
                title="Restaurants"
                icon={RestaurantIcon}
                onClick={() => this.onItemClicked("/")}
                active={this.props.location.pathname === "/"}
              />
              <MenuItemComponent
                title="Orders"
                icon={RestaurantIcon}
                onClick={() => this.onItemClicked("/orders")}
                active={this.props.location.pathname === "/orders"}
              />
              <MenuItemComponent
                title="Managers"
                icon={ManagerIcon}
                onClick={() => this.onItemClicked("/managers")}
                active={this.props.location.pathname === "/managers"}
              />
              <MenuItemComponent
                title="Users"
                icon={UserIcon}
                onClick={() => this.onItemClicked("/users")}
                active={this.props.location.pathname === "/users"}
              />
              <MenuItemComponent
                title="Custom Notification"
                icon={Notification}
                onClick={() => this.onItemClicked("/notifications")}
                active={this.props.location.pathname === "/notifications"}
              />
              <MenuItemComponent
                title="Settings"
                icon={SettingIcon}
                onClick={() => this.onItemClicked("/settings")}
                active={this.props.location.pathname === "/settings"}
              />
              <MenuItemComponent
                title="Logout"
                icon={SettingIcon}
                onClick={() => this.showLogoutConfirm(logout)}
                // active={this.props.location.pathname === "/settings"}
              />
            </Column>
          </Column>
          {/* {expanded && (
            <div
              className={css(styles.outsideLayer)}
              onClick={() => this.toggleMenu()}
            ></div>
          )} */}
        </Row>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
});
export default connect(null, mapDispatchToProps)(withRouter(SidebarComponent));
