import React, { Fragment } from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite/no-important";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router-dom";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import CardImage from "assets/img/user.png";

const { confirm } = Modal;

const styles = StyleSheet.create({
  earningTable: {
    marginTop: 40,
    backgroundColor: "white",
    borderRadius: 5,
    padding: 20,
    boxShadow: "0 0 10px -3px grey"
  },
  notificationTable: {
    marginTop: 30,
    backgroundColor: "white",
    padding: 20,
    width: "100%"
  },
  orderDetailTable: {
    marginTop: 40,
    backgroundColor: "white",
    borderRadius: 5,
    padding: 20
  },

  userRow: {
    width: "100%",
    padding: 5,
    borderBottom: "0.1px solid lightgrey"
  },
  userImage: {
    height: 50,
    width: 50,
    marginRight: 5,
    borderRadius: "50%"
  },
  mainText: {
    fontWeight: "bold",
    fontSize: 13,
    lineHeight: 1
    // marginBottom: 10,
  },
  orderTitle: {
    fontWeight: "800",
    fontSize: 14,
    lineHeight: 1
  },
  subText: {
    fontWeight: "bold",
    color: "#444444"
  },
  restaurantInfo: {
    fontWeight: "bold",
    color: "#3EB64A"
  },
  wrapper: {
    "@media (max-width: 415px)": {
      fontSize: 10,
      textAlign: "justify"
    }
  }
});

const showChangeStatusConfirm = params => {
  confirm({
    title: `Are you sure?`,
    icon: <ExclamationCircleOutlined color="green" />,
    content: `Are you sure  you want to ${params.type} ${params.item.fullName} ?`,
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk() {
      params.changeStatus({
        userId: params.item._id,
        newStatus: params.status
      });
    }
    // onCancel() {
    // },
  });
};
const UserTable = ({
  data,
  history,
  forwardPath,
  isNotificationPage,
  changeStatus
}) => {
  return (
    <Column
      flex={1}
      horizontal="start"
      vertical="center"
      className={
        isNotificationPage
          ? css(styles.notificationTable)
          : css(styles.earningTable)
      }
    >
      {data.map((item, index) => (
        <div
          id={item._id}
          className={index !== data.length - 1 && css(styles.userRow)}
          style={{
            width: "100%",
            padding: 5,
            paddingTop: 10,
            paddingBottom: 10,
            cursor: "pointer"
          }}
        >
          <Row horizontal="space-between" vertical="center">
            <div
              className="flexer"
              // onClick={() => history.push(forwardPath)}
            >
              <img
                alt="avatar"
                src={item.profilePicture ? item.profilePicture : CardImage }
                className={css(styles.userImage)}
              />
              <Column className="ml-2 p-2 mt-2">
                <Row className={css(styles.wrapper)}>
                  <p className="m-0 p-0 font-weight-bold">{item.fullName} </p>
                  <p className="font-weight-bold">
                    &nbsp; - Email:{" "}
                    <span style={{ fontWeight: "500" }}>{item.email}</span>
                  </p>
                </Row>
                <p className="text-muted" style={{ fontSize: 12 }}>
                  {moment(item.dob).format("MMMM DD YYYY")}
                </p>
              </Column>
            </div>
            {!isNotificationPage && (
              <Row horizontal="center" vertical="center" className="m-0 p-0">
                <Fragment>
                  <FontAwesomeIcon
                    icon={faBan}
                    color={item.status !== "blocked" ? "#a8a8a8" : "red"}
                    className="mr-2"
                  />
                  {item.status !== "blocked" ? (
                    <span
                      className="text-muted"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        showChangeStatusConfirm({
                          changeStatus,
                          item,
                          type: "block",
                          status: "blocked"
                        })
                      }
                    >
                      Block
                    </span>
                  ) : (
                    <span
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() =>
                        showChangeStatusConfirm({
                          changeStatus,
                          item,
                          type: "unblock",
                          status: "approved"
                        })
                      }
                    >
                      Blocked
                    </span>
                  )}
                </Fragment>
              </Row>
            )}
          </Row>
        </div>
      ))}
    </Column>
  );
};

export default withRouter(UserTable);
