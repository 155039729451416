import MapImage from "assets/img/map-bg.png";

export const branchesData = [
  {
    id: 1,
    image: MapImage,
    addressHead: "1330 N. La Brea Ave",
    addressSubtitle: "Inglewood, CA 90203"
  },

  {
    id: 2,
    image: MapImage,
    addressHead: "1330 N. La Brea Ave",
    addressSubtitle: "Inglewood, CA 90203"
  },

  {
    id: 3,
    image: MapImage,
    addressHead: "1330 N. La Brea Ave",
    addressSubtitle: "Inglewood, CA 90203"
  }
];
