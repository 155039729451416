import { managersActionsTypes } from "./managers.action.types";

export const getManagersRequest = () => ({
  type: managersActionsTypes.GET_MANAGERS_REQUEST
});

export const getManagersSuccess = managers => ({
  type: managersActionsTypes.GET_MANAGERS_SUCCESS,
  payload: managers
});

export const getManagersFailure = error => ({
  type: managersActionsTypes.GET_MANAGERS_FAILURE,
  payload: error
});

export const changeManagerStatusRequest = () => ({
  type: managersActionsTypes.CHANGE_MANAGER_STATUS_REQUEST
});

export const changeManagerStatusSuccess = () => ({
  type: managersActionsTypes.CHANGE_MANAGER_STATUS_SUCCESS
});

export const changeManagerStatusFailure = error => ({
  type: managersActionsTypes.CHANGE_MANAGER_STATUS_FAILURE,
  payload: error
});
