export const tabScreenData = [
  {
    tabName: "Recent Orders",
    isActive: true
  },
  {
    tabName: "Favorites Restaurants",
    isActive: false
  }
];
