import React, { Fragment } from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite/no-important";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const styles = StyleSheet.create({
  menuTable: {
    marginTop: 20,
    backgroundColor: "white",
    borderRadius: 5,
    padding: "12px 20px 12px 20px",
    boxShadow: "0 3px 10px -3px grey",
    width: "100%"
  },
  userRow: {
    width: "100%",
    padding: 5
  },
  mainText: {
    fontWeight: "800",
    fontSize: 15,
    margin: 0,
    padding: 0
  },
  onDate: {
    fontWeight: 800,
    fontSize: 11,
    color: "#444444",
    marginRight: 7
  },
  status: {
    fontWeight: 800,
    fontSize: 11,
    color: "#444444",
    marginRight: 7,
    marginBottom: 10
  },
  wrapper: {
    marginBottom: 5,
    "@media (max-width: 415px)": {
      fontSize: 10,
      textAlign: "justify"
    }
  },
  orderDateWrapper: {
    display: "flex",
    alignItems: "start",
    justifyContent: "space-between",
    marginTop: 3
  },
  fontBolder: {
    fontWeight: 800,
    margin: 0,
    padding: 0
  },
  addressBadge: {
    backgroundColor: "#d8f0db",
    padding: "3px 0px 3px 0px",
    borderTopLeftRadius: 40,
    borderBottomLeftRadius: 40,
    borderTopRightRadius: 40,
    borderBottomRightRadius: 40,
    // width: 80,
    color: "#3EB64A",
    textAlign: "center",
    textTransform: "capitalize",
    cursor: "pointer",
    fontSize: 9,
    marginTop: 12
  }
});

const ReservationTab = ({ data, reservations }) => {
  return (
    <Fragment>
      {reservations &&
        reservations.map((item, index) => (
          <Column
            horizontal="start"
            vertical="center"
            className={css(styles.menuTable)}
          >
            <div
              className={index !== data.length - 1 && css(styles.userRow)}
              style={{
                width: "100%",
                padding: 5,
                paddingBottom: 10
              }}
            >
              <Row horizontal="space-between" verticaL="start">
                <Column vertical="start" horizontal="start">
                  <Column className={css(styles.wrapper)}>
                    <p className={css(styles.mainText)}>
                      Booking no.{" "}
                      <span className="font-weight-normal">
                        #{item.bookingNumber}
                      </span>{" "}
                    </p>
                    <div className={css(styles.orderDateWrapper)}>
                      <p className={css(styles.onDate)}>
                        On{" "}
                        <span className="font-weight-normal">
                          {item.createdAt}
                        </span>
                      </p>
                      <p className={css(styles.onDate)}>
                        Request Date{" "}
                        <span className="font-weight-normal">{item.date}</span>
                      </p>
                      <p className={css(styles.onDate)}>
                        Time{" "}
                        <span className="font-weight-normal">{item.date}</span>
                      </p>
                    </div>
                    <div className={css(styles.addressBadge)}>
                      {item.branch.address}
                    </div>
                  </Column>

                  {/* <p className={css(styles.subText)}>{item.subText}</p> */}
                </Column>
                <Column horizontal="center" vertical="center">
                  <p className={`${css(styles.status)} `}>
                    Status:{" "}
                    <span style={{ color: "#3EB64A", fontWeight: 600 }}>
                      {item.status}
                    </span>
                  </p>
                  <h3 className={css(styles.fontBolder)}>{item.guest}</h3>
                  <p className="text-muted m-0 p-0" style={{ fontSize: 10 }}>
                    Guests
                  </p>
                </Column>
              </Row>
            </div>
          </Column>
        ))}
    </Fragment>
  );
};

const mapStateToProps = ({ restaurants: { reservations } }) => ({
  reservations
});

export default connect(mapStateToProps)(withRouter(ReservationTab));
