import React, { Component } from "react";
import "./Login.css";
import Logo from "assets/img/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { adminLoginMiddleware } from "store/admin/admin.middleware";
import Loader from "components/Loader/Loader";
import { Modal } from "antd";
class Login extends Component {
  state = {
    email: "",
    password: ""
  };
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  componentDidMount() {
    const { status } = this.props;
    if (status === "profile edit success") {
      this.countDown();
    }
  }
  handleSubmit = event => {
    event.preventDefault();
    const { email, password } = this.state;
    if (email && password) {
      this.props.login({ email, password });
    }
  };
  componentDidUpdate(prevProps) {
    const { isLoggedIn, status, message } = this.props;
    if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
      if (isLoggedIn === true) {
        this.props.history.push("/");
      }
    }
    if (prevProps.status !== status) {
      if (status === "admin login failure") {
        this.info(message);
      }
    }
  }

  countDown = () => {
    let secondsToGo = 10;
    const modal = Modal.success({
      title: "Admin profile Update",
      content: "Profile has updated successfully!"
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
      // modal.update({
      //   content: 'Profile has updated successfully!',
      // });
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      modal.destroy();
    }, secondsToGo * 1000);
  };
  info = message => {
    Modal.error({
      title: "Error!",
      content: (
        <div>
          <p>{message}</p>
        </div>
      ),
      onOk() {}
    });
  };
  render() {
    const { email, password } = this.state;
    const { isloading } = this.props;
    return (
      <div className="container-fluid vh-100 flexer">
        <div className="row vw-100 flexer vh-100 login-wrapper">
          <div className="col-lg-6 col-md-10 col-sm-10 login-container-card flexer-column">
            <div className="flexer-column" style={{ marginBottom: 60 }}>
              <img src={Logo} alt="login-logo" className="login-logo" />
              <h1 className="product-heading">Merchant Nation</h1>
            </div>
            <form className="flexer-column login-form w-75">
              <input
                onChange={this.handleChange}
                type="email"
                value={email}
                name="email"
                placeholder="Email *"
                className="form-input-field w-100"
              />
              <input
                onChange={this.handleChange}
                type="password"
                value={password}
                name="password"
                placeholder="Password *"
                className="form-input-field TB w-100"
              />
              <h6
                className="forgot-pass mt-2"
                onClick={() => this.props.history.push("/forget-password")}
              >
                Forgot Password?
              </h6>
              <button
                onClick={this.handleSubmit}
                type="submit"
                className="w-100 login-btn mt-5"
              >
                Sign In
                <FontAwesomeIcon
                  className="mt-1 mr-3"
                  style={{ float: "right" }}
                  icon={faArrowRight}
                />
              </button>
            </form>
          </div>
        </div>
        {!!isloading && <Loader />}
      </div>
    );
  }
}
const mapStateToProps = ({
  admin: { isLoggedIn, isloading, status, message }
}) => ({ isLoggedIn, isloading, status, message });
const mapDispatchToProps = dispatch => ({
  login: data => dispatch(adminLoginMiddleware(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
