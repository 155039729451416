import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { authReducer } from "./authReducer";
import { globalReducer } from "./globalReducer";
import adminReducer from "store/admin/admin.reducers";
import usersReducer from "store/users/users.reducers";
import ordersReducer from "store/orders/orders.reducers";
import managersReducer from "./../managers/managers.reducers";
import restaurantsReducer from "./../restaurants/restaurants.reducers";
import notificationReducer from "./../notifications/notification.reducer";

const config = {
  key: "root",
  storage,
  whitelist: ["admin"]
  // blackList: ['authReducer', 'globalReducer']
};

const rootReducer = combineReducers({
  authReducer: authReducer,
  globalReducer: globalReducer,
  admin: adminReducer,
  users: usersReducer,
  orders: ordersReducer,
  managers: managersReducer,
  restaurants: restaurantsReducer,
  notification: notificationReducer
});

export default persistReducer(config, rootReducer);
