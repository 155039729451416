import { ordersActionsTypes } from "./orders.action.types";

export const getOrdersRequest = () => ({
  type: ordersActionsTypes.GET_ORDERS_REQUEST
});

export const getOrdersSuccess = orders => ({
  type: ordersActionsTypes.GET_ORDERS_SUCCESS,
  payload: orders
});

export const getOrdersFailure = error => ({
  type: ordersActionsTypes.GET_ORDERS_FAILURE,
  payload: error
});

export const getOrderDetails = orderId => ({
  type: ordersActionsTypes.GET_ORDER_DETAILS,
  payload: orderId
});
