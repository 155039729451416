import { notificationsActionTypes } from "./notification.action.types";

export const sendNotificationRequest = () => ({
  type: notificationsActionTypes.NOTIFICATION_SEND_REQUEST
});

export const sendNotificationSuccess = notification => ({
  type: notificationsActionTypes.NOTIFICATION_SEND_SUCCESS,
  payload: notification
});

export const sendNotificationFailure = error => ({
  type: notificationsActionTypes.NOTIFICATION_SEND_FAILURE,
  payload: error
});

export const getNotficationRequest = () => ({
  type: notificationsActionTypes.GET_USER_NOTIFICATIONS_REQUEST
});

export const getNotficationSuccess = notification => ({
  type: notificationsActionTypes.GET_USER_NOTIFICATIONS_SUCCESS,
  payload: notification
});

export const getNotficationFailure = error => ({
  type: notificationsActionTypes.GET_USER_NOTIFICATIONS_FAILURE,
  payload: error
});


export const getAllNotficationRequest = () => ({
  type: notificationsActionTypes.GET_ALL_NOTIFICATIONS_REQUEST
});

export const getAllNotficationSuccess = notifications => ({
  type: notificationsActionTypes.GET_ALL_NOTIFICATIONS_SUCCESS,
  payload: notifications
});

export const getAllNotficationFailure = error => ({
  type: notificationsActionTypes.GET_ALL_NOTIFICATIONS_FAILURE,
  payload: error
});