export const notificationsActionTypes = {
  NOTIFICATION_SEND_REQUEST: "NOTIFICATION_SEND_REQUEST",
  NOTIFICATION_SEND_SUCCESS: "NOTIFICATION_SEND_SUCCESS",
  NOTIFICATION_SEND_FAILURE: "NOTIFICATION_SEND_FAILURE",

  GET_USER_NOTIFICATIONS_REQUEST: "GET_USER_NOTIFICATIONS_REQUEST",
  GET_USER_NOTIFICATIONS_SUCCESS: "GET_USER_NOTIFICATIONS_SUCCESS",
  GET_USER_NOTIFICATIONS_FAILURE: "GET_USER_NOTIFICATIONS_FAILURE",

  GET_ALL_NOTIFICATIONS_REQUEST: "GET_ALL_NOTIFICATIONS_REQUEST",
  GET_ALL_NOTIFICATIONS_SUCCESS: "GET_ALL_NOTIFICATIONS_SUCCESS",
  GET_ALL_NOTIFICATIONS_FAILURE: "GET_ALL_NOTIFICATIONS_FAILURE"
};
