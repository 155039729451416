import React, { Fragment } from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite/no-important";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router-dom";

const styles = StyleSheet.create({
  earningTable: {
    marginTop: 70,
    backgroundColor: "white",
    borderRadius: 5,
    padding: 18,
    boxShadow: "0 3px 10px -3px grey"
  },

  userRow: {
    width: "100%",
    padding: 5
  },
  orderImage: {
    height: 60,
    width: 60,
    marginRight: 5,
    borderRadius: "50%"
  },
  userImage: {
    height: 80,
    width: 80,
    marginRight: 5,
    marginLeft: 20,
    borderRadius: "50%",
    "@media (max-width: 415px)": {
      height: 40,
      width: 40
    }
  },
  mainText: {
    fontWeight: "bolder",
    fontSize: 17,
    "@media (max-width: 415px)": {
      fontSize: 10
    }
  },
  subText: {
    fontWeight: "bold",
    color: "#444444",
    fontSize: 12
  },
  restaurantInfo: {
    fontWeight: "bold",
    color: "#3EB64A"
  },
  mobileNumber: {
    marginTop: 28,
    marginLeft: 30
  },
  joinDate: {
    color: "grey",
    fontSize: 10,
    marginTop: 10
  },
  wrapper: {
    "@media (max-width: 415px)": {
      fontSize: 10,
      textAlign: "justify"
    }
  }
});

const InformationBanner = ({ data, isUserPage, isOrderPage }) => {
  return (
    <Column
      flex={1}
      horizontal="start"
      vertical="center"
      className={css(styles.earningTable)}
    >
      <div className={css(styles.userRow)}>
        <Row horizontal="space-between" vertical="center">
          {isOrderPage && (
            <div className="flexer">
              <img
                alt="avatar"
                src={data?.restaurant?.image}
                className={css(styles.orderImage)}
              />
              <Column className="ml-1 p-2 mt-2">
                <Row>
                  <p className={css(styles.mainText)}>
                    {data?.restaurant?.name}{" "}
                  </p>
                </Row>
                <p className={css(styles.subText)}>
                  <span>"Order No." </span>
                  {data?.orderNumber}
                </p>
              </Column>
            </div>
          )}
          {isUserPage && (
            <Fragment>
              <div className="flexer ">
                <img
                  alt="avatar"
                  src={data?.avatar}
                  className={css(styles.userImage)}
                />
                <Column className="ml-1 p-2 mt-2">
                  <Row>
                    <p className={css(styles.mainText)}>{data.mainText} </p>
                  </Row>
                  <p className="text-muted" style={{ fontSize: 12 }}>
                    {data?.subText}
                  </p>
                </Column>
              </div>
            </Fragment>
          )}
          <Column horizontal="center" vertical="center" className="m-0 p-0">
            {isUserPage ? (
              <Fragment>
                <Row alignSelf="end">
                  <FontAwesomeIcon
                    icon={faBan}
                    color="#a8a8a8"
                    className="mr-1 mt-1"
                  />
                  <span className="text-muted m-0 p-0">Block</span>
                </Row>
                <p className={css(styles.joinDate)}>Joined On, Mon 12/12/19</p>
              </Fragment>
            ) : (
              <div className="flexer-column">
                <span className="text-muted" style={{ fontSize: 12 }}>
                  {data?.status}
                </span>
                <h5 className="align-end font-weight-bold mt-2">
                  ${data?.grossAmount}
                </h5>
              </div>
            )}
          </Column>
        </Row>
      </div>
      {isUserPage && (
        <Row className={css(styles.mobileNumber)}>
          <h6 className="font-weight-bolder">
            Mobile Number:{" "}
            <span className="font-weight-normal">(+1) 768 4565 342 3</span>
          </h6>
        </Row>
      )}
    </Column>
  );
};

export default withRouter(InformationBanner);
