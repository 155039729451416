import { managersActionsTypes } from "./managers.action.types";
const INITIAL_STATE = {
  managers: null,
  isloading: false,
  message: null,
  status: ""
};

const managersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case managersActionsTypes.GET_MANAGERS_REQUEST:
      return Object.assign({
        ...state,
        isloading: true,
        status: "get managers request"
      });
    case managersActionsTypes.GET_MANAGERS_SUCCESS:
      return Object.assign({
        ...state,
        isloading: false,
        managers: action.payload,
        status: "get managers success"
      });
    case managersActionsTypes.GET_MANAGERS_FAILURE:
      return Object.assign({
        ...state,
        isloading: false,
        message: action.payload,
        status: "get managers failure"
      });
    case managersActionsTypes.CHANGE_MANAGER_STATUS_REQUEST:
      return Object.assign({
        ...state,
        isloading: true,
        status: "change manager status request"
      });
    case managersActionsTypes.CHANGE_MANAGER_STATUS_SUCCESS:
      return Object.assign({
        ...state,
        isloading: false,
        managers: null,
        status: "change manager status success"
      });
    case managersActionsTypes.CHANGE_MANAGER_STATUS_FAILURE:
      return Object.assign({
        ...state,
        isloading: false,
        status: "change manager status falilure",
        message: action.payload
      });
    default:
      return state;
  }
};

export default managersReducer;
