import React, { Fragment } from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite/no-important";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const styles = StyleSheet.create({
  menuTable: {
    marginTop: 20,
    backgroundColor: "white",
    borderRadius: 5,
    padding: "5px 20px 5px 5px",
    boxShadow: "0 3px 10px -3px grey",
    width: "100%"
  },
  userRow: {
    width: "100%",
    padding: 5
  },
  giftCardImage: {
    height: 100,
    width: 150,
    marginRight: 20
  },
  mainText: {
    fontWeight: "800",
    fontSize: 15,
    margin: 0,
    padding: 0
  },
  subText: {
    color: "#b2b2b2",
    fontWeight: 500,
    letterSpacing: 0.3,
    marginTop: 10
  },
  restaurantInfo: {
    fontWeight: "bold",
    textTransform: "capitalize"
  },
  wrapper: {
    marginBottom: 5,
    "@media (max-width: 415px)": {
      fontSize: 10,
      textAlign: "justify"
    }
  }
});

const RestaurantGiftCard = props => {
  const { giftCards } = props;
  return (
    <Fragment>
      {giftCards &&
        giftCards.map((item, index) => (
          <Column
            horizontal="start"
            vertical="center"
            className={css(styles.menuTable)}
          >
            <div
              className={index !== giftCards.length - 1 && css(styles.userRow)}
              style={{
                width: "100%",
                padding: 5,
                paddingTop: 10,
                paddingBottom: 10
              }}
            >
              <Row
                breakpoints={{ 440: "column" }}
                horizontal="space-between"
                vertical="center"
              >
                <div className="flexer">
                  <img
                    alt="avatar"
                    src={item.image}
                    className={css(styles.giftCardImage)}
                  />
                  <Column className="ml-2 p-2 mt-2">
                    <Row className={css(styles.wrapper)}>
                      <p className={css(styles.mainText)}>{item.title} </p>
                    </Row>

                    <p className={css(styles.subText)}>{item.description}</p>
                  </Column>
                </div>
                <Row
                  horizontal="center"
                  vertical="center"
                  alignSelf="start"
                  className="mt-4"
                >
                  <div className="flexer-column">
                    <p className="align-end font-weight-bolder">
                      ${item.price}
                    </p>
                  </div>
                </Row>
              </Row>
            </div>
          </Column>
        ))}
    </Fragment>
  );
};
const mapStateToProps = ({ restaurants: { giftCards } }) => ({ giftCards });

export default connect(mapStateToProps)(withRouter(RestaurantGiftCard));
