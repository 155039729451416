import { adminActionTypes } from "./admin.action.types";

export const loginRequest = () => ({
  type: adminActionTypes.LOGIN_REQUEST
});

export const loginSuccess = admin => ({
  type: adminActionTypes.LOGIN_SUCCESS,
  payload: admin
});

export const loginFailure = error => ({
  type: adminActionTypes.LOGIN_FAILURE,
  payload: error
});

export const editProfileRequest = payload => ({
  type: adminActionTypes.ADIMIN_EDIT_PROFILE_REQUEST,
  payload
});

export const editProfileSuccess = () => ({
  type: adminActionTypes.ADIMIN_EDIT_PROFILE_SUCCESS
});

export const editProfileFailure = error => ({
  type: adminActionTypes.ADIMIN_EDIT_PROFILE_FAILURE,
  payload: error
});

export const logout = () => ({
  type: adminActionTypes.LOGOUT
});

export const sendEmailForPasswordRecoveryRequest = () => ({
  type: adminActionTypes.SEND_EMAIL_FOR_PASSWORD_RECOVERY_REQUEST
});

export const sendEmailForPasswordRecoverySuccess = payload => ({
  type: adminActionTypes.SEND_EMAIL_FOR_PASSWORD_RECOVERY_SUCCESS,
  payload
});

export const sendEmailForPasswordRecoveryFailure = error => ({
  type: adminActionTypes.SEND_EMAIL_FOR_PASSWORD_RECOVERY_FAILURE,
  payload: error
});

export const verifySecurityCodeRequest = () => ({
  type: adminActionTypes.VERIFY_SECURITY_CODE_REQUEST
});

export const verifySecurityCodeSuccess = payload => ({
  type: adminActionTypes.VERIFY_SECURITY_CODE_SUCCESS,
  payload
});

export const verifySecurityCodeFailure = error => ({
  type: adminActionTypes.VERIFY_SECURITY_CODE_FAILURE,
  payload: error
});

export const resetPasswordRequest = () => ({
  type: adminActionTypes.RESET_PASSWORD_REQUEST
});

export const resetPasswordSuccess = payload => ({
  type: adminActionTypes.RESET_PASSWORD_SUCCESS,
  payload
});

export const resetPasswordFailure = error => ({
  type: adminActionTypes.RESET_PASSWORD_FAILURE,
  payload: error
});
