import React from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite/no-important";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getOrderDetails } from "store/orders/orders.actions";

const styles = StyleSheet.create({
  earningTable: {
    marginTop: 40,
    backgroundColor: "white",
    borderRadius: 5,
    padding: 20,
    boxShadow: "0 0 10px -3px grey"
  },
  orderDetailTable: {
    marginTop: 40,
    backgroundColor: "white",
    borderRadius: 5,
    padding: 20
  },

  userRow: {
    width: "100%",
    padding: 5,
    borderBottom: "0.1px solid lightgrey"
  },
  userImage: {
    height: 50,
    width: 50,
    marginRight: 5,
    borderRadius: "50%"
  },
  mainText: {
    fontWeight: 800,
    fontSize: 13,
    lineHeight: 1
    // marginBottom: 10,
  },
  orderTitle: {
    fontWeight: "800",
    fontSize: 14,
    lineHeight: 1
  },
  subText: {
    fontWeight: 600,
    color: "#444444",
    fontSize: 12
  },
  restaurantInfo: {
    fontWeight: "bold",
    color: "#3EB64A"
  },
  wrapper: {
    "@media (max-width: 415px)": {
      fontSize: 10,
      textAlign: "justify"
    }
  }
});
const getPercentAge = (item) => {
  console.log(item.menu.price, item.menu.discount)
  return (item.menu.price - (item.menu.price * (item.discount / 100)))
}
const getItemPrice = (item) => {
  switch (item.type) {
    case "offer":
      return `$ ${getPercentAge(item)?.toFixed(2)}`
    case "reward":
      return `reward`
    default:
      return item.price?.toFixed(2)
  }
}
const OrdersList = ({
  data,
  tableHeading,
  isHeaderAllowed,
  history,
  isOrderPage,
  forwardPath,
  isOrderDetailPage,
  isUserOrders,
  getDetails
}) => {

  return (
    <Column
      flex={1}
      horizontal="start"
      vertical="center"
      className={
        isOrderDetailPage
          ? css(styles.orderDetailTable)
          : css(styles.earningTable)
      }
    >
      {isHeaderAllowed && (
        <div className="w-100" style={{ borderBottom: "1px solid lightgrey" }}>
          <h4 className="m-0 mb-2" style={{ fontWeight: "800" }}>
            {tableHeading}
          </h4>
        </div>
      )}

      {!!data &&
        data.map((item, index) => (
          <div
            className={index !== data.length - 1 && css(styles.userRow)}
            style={{
              width: "100%",
              padding: 5,
              paddingTop: 10,
              paddingBottom: 10,
              cursor: "pointer"
            }}
            onClick={() =>
              !isOrderDetailPage && !isUserOrders
                ? (getDetails(item._id), history.push(forwardPath))
                : null
            }
          >
            <Row horizontal="space-between" vertical="center">
              <div className="flexer">
                {!isOrderDetailPage && (
                  <img
                    alt="avatar"
                    src={item.restaurant?.image}
                    className={css(styles.userImage)}
                  />
                )}
                <Column className="ml-2 p-2 mt-2">
                  {isOrderPage && (
                    <Row>
                      <p className={css(styles.mainText)}>
                        {item.restaurant?.name}{" "}
                      </p>
                    </Row>
                  )}

                  {isOrderDetailPage && (
                    <Row>
                      <p className={css(styles.orderTitle)}>
                        {item.quantity} x {item.title}{" "}
                      </p>
                    </Row>
                  )}
                  <p className={css(styles.subText)}>
                    <span className="font-weight-bold">
                      {isOrderPage && "Order No."}{" "}
                    </span>
                    {item.orderNumber}
                  </p>
                </Column>
              </div>
              <Row horizontal="center" vertical="center" className="m-0 p-0">
                <div className="flexer-column">
                  {!isOrderDetailPage && (
                    <span className="text-muted" style={{ fontSize: 10 }}>
                      {item.status}
                    </span>
                  )}
                  {isOrderDetailPage ? (
                    <h5 className="align-end mt-1">{getItemPrice(item)}</h5>
                  ) : (
                      <h5 className="align-end mt-1">${item.grossAmount?.toFixed(2)}</h5>
                    )}
                </div>
              </Row>
            </Row>
          </div>
        ))}
    </Column>
  );
};
const mapDisptchToProps = dispatch => ({
  getDetails: orderId => dispatch(getOrderDetails(orderId))
});
export default connect(null, mapDisptchToProps)(withRouter(OrdersList));
