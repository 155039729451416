import CardImage from "assets/img/orderImg.png";

export const data = [
  {
    id: 1,
    mainText: "Fiesta martin",
    subText: "3345989656",
    avatar: CardImage,
    earnings: "5.00",
  },
  {
    id: 2,
    mainText: "Fiesta martin",
    subText: "3345989656",
    avatar: CardImage,
    earnings: "5.00",
  },
  {
    id: 3,
    mainText: "Fiesta martin",
    subText: "3345989656",
    avatar: CardImage,
    earnings: "5.00",
  },
  {
    id: 4,
    mainText: "Fiesta martin",
    subText: "3345989656",
    avatar: CardImage,
    earnings: "5.00",
  },
  {
    id: 5,
    mainText: "Fiesta martin",
    subText: "3345989656",
    avatar: CardImage,
    earnings: "5.00",
  },
  {
    id: 6,
    mainText: "Fiesta martin",
    subText: "3345989656",
    avatar: CardImage,
    earnings: "5.00",
  },
  {
    id: 7,
    mainText: "Fiesta martin",
    subText: "3345989656",
    avatar: CardImage,
    earnings: "5.00",
  },
  {
    id: 8,
    mainText: "Fiesta martin",
    subText: "3345989656",
    avatar: CardImage,
    earnings: "5.00",
  },
];

export const searchBoxData = [
  {
    id: 1,
    name: "Show All",
    isActive: true,
  },
  {
    id: 2,
    name: "Pending",
    isActive: false,
  },
  {
    id: 3,
    name: "Processing",
    isActive: false,
  },
  {
    id: 4,
    name: "Received",
    isActive: false,
  },
  // {
  //   id: 5,
  //   name: "Cancelled",
  //   isActive: false
  // },
  // {
  //   id: 6,
  //   name: "Delivered",
  //   isActive: false
  // }
];
