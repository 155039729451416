import CardImage from "assets/img/userImage.png";

export const formData = {
  DropDown: [
    {
      dropDownName: "Select Restaurants",
      MenuItem: ["dummy one", "dummy  two"]
    },
    {
      dropDownName: "Select Branches Name",
      MenuItem: ["dummy one", "dummy two"]
    },
    {
      dropDownName: "Select Item Type",
      MenuItem: ["Menu", "Gift card", "Offer", "Reward"]
    },
    {
      dropDownName: "Select Item Name",
      MenuItem: ["dummy one", "dummy two"]
    }
  ],
  userLocation: {
    fields: [
      {
        type: "text",
        name: "city",
        required: false,
        placeholder: "City"
      },
      {
        type: "text",
        name: "state",
        required: false,
        placeholder: "State"
      },
      {
        type: "text",
        name: "zipCode",
        required: false,
        placeholder: "Enter Zipcode"
      }
    ]
  },

  Select: {
    options: [
      {
        name: "Restaurant",
        isActive: true
      },
      {
        name: "Branch",
        isActive: false
      },
      {
        name: "Radius",
        isActive: false
      }
    ]
  },

  OrderItem: {
    item: [
      {
        name: "Restaurant",
        isActive: true
      },
      {
        name: "Item Type",
        isActive: false
      },
      {
        name: "Item Name",
        isActive: false
      }
    ]
  },

  scheduleNotfications: {
    fields: [
      {
        type: "text",
        name: "Day",
        required: false,
        placeholder: "Select Day"
      },
      {
        type: "time",
        name: "time",
        required: false,
        placeholder: "Select Time"
      }
    ]
  }
};

export const userData = [
  {
    id: 1,
    mainText: "Mathew Evans",
    subText: "Mon 12/12/19",
    avatar: CardImage,
    userEmail: "mathewvans@gmail.com"
  },
  {
    id: 2,
    mainText: "Mathew Evans",
    subText: "Mon 12/12/19",
    avatar: CardImage,
    userEmail: "mathewvans@gmail.com"
  },
  {
    id: 3,
    mainText: "Mathew Evans",
    subText: "Mon 12/12/19",
    avatar: CardImage,
    userEmail: "mathewvans@gmail.com"
  },
  {
    id: 4,
    mainText: "Mathew Evans",
    subText: "Mon 12/12/19",
    avatar: CardImage,
    userEmail: "mathewvans@gmail.com"
  },
  {
    id: 5,
    mainText: "Mathew Evans",
    subText: "Mon 12/12/19",
    avatar: CardImage,
    userEmail: "mathewvans@gmail.com"
  },
  {
    id: 6,
    mainText: "Mathew Evans",
    subText: "Mon 12/12/19",
    avatar: CardImage,
    userEmail: "mathewvans@gmail.com"
  },
  {
    id: 7,
    mainText: "Mathew Evans",
    subText: "Mon 12/12/19",
    avatar: CardImage,
    userEmail: "mathewvans@gmail.com"
  },
  {
    id: 8,
    mainText: "Mathew Evans",
    subText: "Mon 12/12/19",
    avatar: CardImage,
    userEmail: "mathewvans@gmail.com"
  }
];

export const weekDays = [
  {
    initial: "S ",
    isActive: false
  },
  {
    initial: "M",
    isActive: false
  },
  {
    initial: "T",
    isActive: false
  },
  {
    initial: "W",
    isActive: false
  },
  {
    initial: "T ",
    isActive: false
  },
  {
    initial: "F",
    isActive: false
  },
  {
    initial: "S",
    isActive: false
  }
];

export const itemTypes = [
  {
    type: "Menu",
    value: "menu"
  },
  {
    type: "Gift card",
    value: "giftCard"
  },
  {
    type: "Offer",
    value: "offer"
  },
  {
    type: "Reward",
    value: "reward"
  }
];


export const days = [
  {
    day: "Sunday",
    value: 0
  },
  {
    day: "Monday",
    value: 1
  },
  {
    day: "Tuesday",
    value: 2
  },
  {
    day: "Wednesday",
    value: 3
  },
  {
    day: "Thursday",
    value: 4
  },
  {
    day: "Friday",
    value: 5
  },
  {
    day: "Satuday",
    value: 6
  }
];