import React, { Component } from "react";
import HeaderComponent from "components/header/HeaderComponent";
import { Column } from "simple-flexbox";
import { tabScreenData } from "./data";
import InformationBanner from "components/InformationBanner/InformationBanner";
import TabBar from "components/TabBar/TabBar";
import UserImage from "assets/img/userImage.png";

class UserInformation extends Component {
  render() {
    const userData = {
      id: 1,
      mainText: "Mathew Evan",
      subText: "mathewevan@gmail.com",
      avatar: UserImage
    };
    return (
      <div className="flexer">
        <Column style={{ width: "80%" }}>
          <HeaderComponent isBackBtn={true} />
          <InformationBanner data={userData} isUserPage={true} />
          <TabBar tabScreenData={tabScreenData} />
        </Column>
      </div>
    );
  }
}

export default UserInformation;
