export const restuarantsActionsTypes = {
  GET_RESTAURANTS_REQUEST: "GET_RESTAURANTS_REQUEST",
  GET_RESTAURANTS_SUCCESS: "GET_RESTAURANTS_SUCCESS",
  GET_RESTAURANTS_FAILURE: "GET_RESTAURANTS_FAILURE",
  GET_RESTAURANTS_DETAILS: "GET_RESTAURANTS_DETAILS",
  GET_RESTAURANT_MENU_REQUEST: "GET_RESTAURANT_MENU_REQUEST",
  GET_RESTAURANT_MENU_SUCCESS: "GET_RESTAURANT_MENU_SUCCESS",
  GET_RESTAURANT_MENU_FAILURE: "GET_RESTAURANT_MENU_FAILURE",
  GET_RESTAURANT_OFFER_REQUEST: "GET_RESTAURANT_OFFER_REQUEST",
  GET_RESTAURANT_OFFER_SUCCESS: "GET_RESTAURANT_OFFER_SUCCESS",
  GET_RESTAURANT_OFFER_FAILURE: "GET_RESTAURANT_OFFER_FAILURE",
  GET_RESTAURANT_GIFT_CARDS_REQUEST: "GET_RESTAURANT_GIFT_CARDS_REQUEST",
  GET_RESTAURANT_GIFT_CARDS_SUCCESS: "GET_RESTAURANT_GIFT_CARDS_SUCCESS",
  GET_RESTAURANT_GIFT_CARDS_FAILURE: "GET_RESTAURANT_GIFT_CARDS_FAILURE",
  GET_RESTAURANT_RESERVATIONS_REQUEST: "GET_RESTAURANT_RESERVATIONS_REQUEST",
  GET_RESTAURANT_RESERVATIONS_SUCCESS: "GET_RESTAURANT_RESERVATIONS_SUCCESS",
  GET_RESTAURANT_RESERVATIONS_FAILURE: "GET_RESTAURANT_RESERVATIONS_FAILURE",
  GET_RESTAURANT_BRANCHES_REQUEST: "GET_RESTAURANT_BRANCHES_REQUEST",
  GET_RESTAURANT_BRANCHES_SUCCESS: "GET_RESTAURANT_BRANCHES_SUCCESS",
  GET_RESTAURANT_BRANCHES_FAILURE: "GET_RESTAURANT_BRANCHES_FAILURE",
  GET_RESTAURANT_REWARDS_REQUEST: "GET_RESTAURANT_REWARDS_REQUEST",
  GET_RESTAURANT_REWARDS_SUCCESS: "GET_RESTAURANT_REWARDS_SUCCESS",
  GET_RESTAURANT_REWARDS_FAILURE: "GET_RESTAURANT_REWARDS_FAILURE",
  GET_RESTAURANT_BRANCHES_FOR_LOCATION_SUCCESS: "GET_RESTAURANT_BRANCHES_FOR_LOCATION_SUCCESS",
  UPDATE_RESTAURANT_REQUEST: "UPDATE_RESTAURANT_REQUEST",
  UPDATE_RESTAURANT_SUCCESS: "UPDATE_RESTAURANT_SUCCESS",
  UPDATE_RESTAURANT_FAILURE: "UPDATE_RESTAURANT_FAILURE",
  GET_RESTAURANT_ADDONS_REQUEST: "GET_RESTAURANT_ADDONS_REQUEST",
  GET_RESTAURANT_ADDONS_SUCCESS: "GET_RESTAURANT_ADDONS_SUCCESS",
  GET_RESTAURANT_ADDONS_FAILURE: "GET_RESTAURANT_ADDONS_FAILURE",
};
