import { userActionsTypes } from "./users.action.types";
const INITIAL_STATE = {
  users: null,
  isloading: false,
  message: null,
  status: ""
};

const usersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case userActionsTypes.GET_USERS_REQUEST:
      return Object.assign({
        ...state,
        isloading: true,
        status: "get users request"
      });
    case userActionsTypes.GET_USERS_SUCCESS:
      return Object.assign({
        ...state,
        isloading: false,
        users: action.payload,
        status: "get users success",
        isLoggedIn: true
      });
    case userActionsTypes.GET_USERS_FAILURE:
      return Object.assign({
        ...state,
        isloading: false,
        message: action.payload,
        status: "get users failure"
      });
    case userActionsTypes.CHANGE_USER_STATUS_REQUEST:
      return Object.assign({
        ...state,
        isloading: true,
        status: "change user status request"
      });
    case userActionsTypes.CHANGE_USER_STATUS_SUCCESS:
      return Object.assign({
        ...state,
        isloading: false,
        users: null,
        status: "change user status success"
      });
    case userActionsTypes.CHANGE_USER_STATUS_FAILURE:
      return Object.assign({
        ...state,
        isloading: false,
        status: "change user status falilure",
        message: action.payload
      });
    default:
      return state;
  }
};

export default usersReducer;
