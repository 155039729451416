import React from "react";
import { string } from "prop-types";
import { Row, Column } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import GoBackBtn from "components/GoBackBtn/GoBackBtn";
import { connect } from "react-redux";

const styles = StyleSheet.create({
  container: {
    height: 100,
    padding: "15px 10px 10px 10px",
    width: "100%"
  },
  cursorPointer: {
    cursor: "pointer"
  },
  name: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "20px",
    textAlign: "right",
    letterSpacing: 0.2,
    padding: "0px 0px 0px 30px",
    borderLeft: "2px solid lightgrey",
    // border: '1px solid red',
    "@media (max-width: 768px)": {
      display: "none"
    }
  },
  iconWrapper: {
    borderRight: "2px solid lightgrey",
    padding: 3
  }
});

function HeaderComponent(props) {
  const { icon, title, isBackBtn, admin, ...otherProps } = props;
  return (
    <Row
      className={css(styles.container)}
      vertical="center"
      horizontal={!isBackBtn && "end"}
      justifyContent="space-between"
      {...otherProps}
    >
      {isBackBtn && (
        <Column alignSelf="end">
          <GoBackBtn />
        </Column>
      )}
      <Row
        alignSelf="start"
        vertical="center"
        justifyContent="space-evenly"
        className="w-25"
      >
        {/* <div className={css(styles.iconWrapper)}>
          <FontAwesomeIcon
            icon={faBell}
            color="#a7a7a7"
            size="lg"
            className="mr-3"
          />
        </div> */}
        <span className={`${css(styles.name, styles.cursorPointer)}`}>
          {admin?.fullName}
        </span>
      </Row>
    </Row>
  );
}

HeaderComponent.propTypes = {
  title: string
};
const mapStateToProps = ({ admin: { admin } }) => ({ admin });
export default connect(mapStateToProps)(HeaderComponent);
