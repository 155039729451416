import axios from "axios";
import Path from "Config/Path";
import {
  getrestaurantsSuccess,
  getrestaurantsRequest,
  getrestaurantsFailure,
  getrestaurantMenuRequest,
  getrestaurantMenuSuccess,
  getrestaurantMenuFailure,
  getrestaurantOfferSuccess,
  getrestaurantOfferRequest,
  getrestaurantOfferFailure,
  getrestaurantGiftCardsRequest,
  getrestaurantGiftCardsSuccess,
  getrestaurantGiftCardsFailure,
  getrestaurantReservationsRequest,
  getrestaurantReservationsSuccess,
  getrestaurantReservationsFailure,
  getrestaurantBranchesRequest,
  getrestaurantBranchesSuccess,
  getrestaurantBranchesFailure,
  getrestaurantRewardsRequest,
  getrestaurantRewardsSuccess,
  getrestaurantRewardsFailure,
  getrestaurantBranchesForLocationSuceess,
  updateRestaurantRequest,
  updateRestaurantSuccess,
  updateRestaurantFailure,
  getrestaurantAddonsRequest,
  getrestaurantAddonsSuccess,
  getrestaurantAddonsFailure,
} from "./restaurants.actions";

export const getrestaurantsMiddleware = (data) => {
  return async (dispatch) => {
    const params = {};
    //data.ownerId && (params.owner = data.ownerId);
    data.query && (params.resname = data.query);
    data.filter && (params.status = data.filter);
    try {
      dispatch(getrestaurantsRequest());
      const response = await axios.get(`${Path.GET_RESTAURANTS}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.token}`,
        },
        params,
      });
      dispatch(getrestaurantsSuccess(response.data.Restaurant));
    } catch (error) {
      dispatch(
        getrestaurantsFailure({
          success: false,
          errorMessage: "Something went wrong please try again",
        })
      );
    }
  };
};

export const getrestaurantMenuMiddleware = (data) => {
  return async (dispatch) => {
    try {
      dispatch(getrestaurantMenuRequest());
      const response = await axios.get(
        `${Path.GET_RESTAURANT_MENU}/${data.restaurantId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
          // params: {
          //   restaurant: ,
          //   populateCategory: true,
          // },
        }
      );
      dispatch(getrestaurantMenuSuccess(response.data.Menu));
    } catch (error) {
      dispatch(
        getrestaurantMenuFailure({
          success: false,
          errorMessage: "Something went wrong please try again",
        })
      );
    }
  };
};

export const getrestaurantofferMiddleware = (data) => {
  return async (dispatch) => {
    try {
      dispatch(getrestaurantOfferRequest());
      const response = await axios.get(
        `${Path.GET_RESTAURANT_OFFER}/${data.restaurantId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
          // params: {
          //   restaurant: data.restaurantId,
          // },
        }
      );
      dispatch(getrestaurantOfferSuccess(response.data.Offer));
    } catch (error) {
      dispatch(
        getrestaurantOfferFailure({
          success: false,
          errorMessage: "Something went wrong, please try again.",
        })
      );
    }
  };
};

export const getrestaurantGiftCardsMiddleware = (data) => {
  return async (dispatch) => {
    try {
      dispatch(getrestaurantGiftCardsRequest());
      const response = await axios.get(
        `${Path.GET_RESTAURANT_GIFT_CARDS}/${data.restaurantId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
          // params: {
          //   restaurant: data.restaurantId,
          // },
        }
      );
      dispatch(getrestaurantGiftCardsSuccess(response.data.GiftCard));
    } catch (error) {
      dispatch(
        getrestaurantGiftCardsFailure({
          success: false,
          errorMessage: "Something went wrong please try again",
        })
      );
    }
  };
};

export const getrestaurantReservationsMiddleware = (data) => {
  return async (dispatch) => {
    try {
      dispatch(getrestaurantReservationsRequest());
      const response = await axios.get(
        `${Path.GET_RESTAURANT_RESERVATIONS}/${data.restaurantId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
          // params: {
          //   restaurant: data.restaurantId,
          //   populateBranch: true,
          // },
        }
      );
      dispatch(getrestaurantReservationsSuccess(response.data.Reservation));
    } catch (error) {
      dispatch(
        getrestaurantReservationsFailure({
          success: false,
          errorMessage: "Something went wrong please try again",
        })
      );
    }
  };
};

export const getrestaurantBranchessMiddleware = (data) => {
  return async (dispatch) => {
    try {
      dispatch(getrestaurantBranchesRequest());
      const response = await axios.get(
        `${Path.GET_RESTAURANT_BRANCHES}/${data.restaurantId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
          // params: {
          //   restaurant: data.restaurantId,
          // },
        }
      );
      if (data.branchesForRestaurantLocation) {
        dispatch(getrestaurantBranchesForLocationSuceess(response.data.Branch));
      } else {
        dispatch(getrestaurantBranchesSuccess(response.data.Branch));
      }
    } catch (error) {
      dispatch(
        getrestaurantBranchesFailure({
          success: false,
          errorMessage: "Something went wrong please try again",
        })
      );
    }
  };
};

export const getrestaurantRewardsMiddleware = (data) => {
  return async (dispatch) => {
    try {
      dispatch(getrestaurantRewardsRequest());
      const response = await axios.get(`${Path.GET_RESTAURANTS_REWARDS}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.token}`,
        },
        params: {
          restaurant: data.restaurantId,
        },
      });
      dispatch(getrestaurantRewardsSuccess(response.data.data.rewards));
    } catch (error) {
      dispatch(
        getrestaurantRewardsFailure({
          success: false,
          errorMessage: "Something went wrong please try again",
        })
      );
    }
  };
};

export const getrestaurantAddOnsMiddleware = (data) => {
  return async (dispatch) => {
    try {
      dispatch(getrestaurantAddonsRequest());
      const response = await axios.get(
        `${Path.GET_RESTAURANTS_ADDONS}/${data.restaurantId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
          // params: {
          //   restaurant: data.restaurantId,
          // },
        }
      );
      dispatch(getrestaurantAddonsSuccess(response.data.Addons));
    } catch (error) {
      dispatch(
        getrestaurantAddonsFailure({
          success: false,
          errorMessage: "Something went wrong please try again",
        })
      );
    }
  };
};

export const updateRestaurantMiddleware = (data) => {
  return async (dispatch) => {
    try {
      dispatch(updateRestaurantRequest());
      const response = await axios.put(`${Path.UPDATE_RESTAURANT}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.token}`,
        },
      });
      if (response.status === 200) {
        return dispatch(
          updateRestaurantSuccess({
            restaurantId: data.restaurant,
            status: data.status,
          })
        );
      }
    } catch (error) {
      dispatch(
        updateRestaurantFailure({
          success: false,
          errorMessage: "Something went wrong please try again",
        })
      );
    }
  };
};
