import axios from "axios";
import Path from "Config/Path";
import {
  loginSuccess,
  loginRequest,
  loginFailure,
  editProfileRequest,
  editProfileSuccess,
  editProfileFailure,
  sendEmailForPasswordRecoveryFailure,
  sendEmailForPasswordRecoveryRequest,
  sendEmailForPasswordRecoverySuccess,
  resetPasswordFailure,
  resetPasswordRequest,
  resetPasswordSuccess,
  verifySecurityCodeFailure,
  verifySecurityCodeRequest,
  verifySecurityCodeSuccess,
} from "./admin.actions";
import { Modal } from "antd";

// Authorization: `Bearer ${token}`,
const config = {
  headers: { "Content-Type": "application/json" },
};
export const adminLoginMiddleware = (data) => {
  return async (dispatch) => {
    try {
      dispatch(loginRequest());
      const response = await axios.post(Path.LOGIN, data, config);
      dispatch(loginSuccess(response.data));
    } catch (error) {
      if (error.message === "Request failed with status code 400") {
        dispatch(
          loginFailure({
            success: false,
            errorMessage: "Email or password is incorrect!",
          })
        );
        return;
      }
      dispatch(
        loginFailure({
          success: false,
          errorMessage: "Something went wrong please try again",
        })
      );
    }
  };
};

export const editProfileMiddleware = (data) => {
  return async (dispatch) => {
    let path = "";
    const body = {};
    switch (data.type) {
      case "email":
        path = Path.EDIT_EMAIL;
        body.email = data.email;
        break;
      case "password":
        path = Path.CHANGE_PASSWORD;
        body.currentPassword = data.currentPassword;
        body.newPassword = data.newPassword;
        break;
      default:
        return;
    }
    try {
      dispatch(editProfileRequest());
      const response = await axios.put(path, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.token}`,
        },
      });
      dispatch(editProfileSuccess(response.data));
    } catch (error) {
      dispatch(
        editProfileFailure({
          success: false,
          errorMessage: "Something went wrong please try again",
        })
      );
    }
  };
};

export const sendRecoveryEmailMiddleware = (data) => {
  return async (dispatch) => {
    try {
      dispatch(sendEmailForPasswordRecoveryRequest());
      const response = await axios.post(
        Path.SEND_EMAIL_FOR_PASSWORD_RECOVERY,
        data,
        config
      );
      if (response.status === 200) {
        dispatch(sendEmailForPasswordRecoverySuccess(response.data));
        Modal.success({
          title: "Success",
          content: response.data.message,
        });
      }
    } catch (error) {
      if (error.message === "Request failed with status code 400") {
        Modal.error({
          title: "Failed",
          content: "Email is incorrect!",
        });
      }
      dispatch(
        sendEmailForPasswordRecoveryFailure({
          success: false,
          errorMessage: "Something went wrong please try again",
        })
      );
    }
  };
};

export const verifyCodeMiddleware = (data) => {
  return async (dispatch) => {
    try {
      dispatch(verifySecurityCodeRequest());
      const response = await axios.post(
        Path.VERIFY_SECURITY_CODE,
        data,
        config
      );
      if (response.status === 200) {
        dispatch(verifySecurityCodeSuccess(response.data));
      }
    } catch (error) {
      if (error.message === "Request failed with status code 400") {
        Modal.error({
          title: "Failed",
          content: "Incorrect Security code!",
        });
      }
      dispatch(
        verifySecurityCodeFailure({
          success: false,
          errorMessage: "Something went wrong please try again",
        })
      );
    }
  };
};

export const resetPasswordMiddleware = (data) => {
  return async (dispatch) => {
    try {
      dispatch(resetPasswordRequest());
      const response = await axios.post(Path.RESET_PASSWORD, data, config);
      if (response.status === 200) {
        dispatch(resetPasswordSuccess(response.data));
        Modal.success({
          title: "Success",
          content: `Your Password was Changed Successfully`,
        });
      }
    } catch (error) {
      if (error.message === "Request failed with status code 400") {
        Modal.error({
          title: "Failed",
          content: "Something went wrong!",
        });
      }
      dispatch(
        resetPasswordFailure({
          success: false,
          errorMessage: "Something went wrong please try again",
        })
      );
    }
  };
};
