import React, { Fragment, Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faCheck } from "@fortawesome/free-solid-svg-icons";
import { Row } from "simple-flexbox";
import { Popover, Overlay } from "react-bootstrap";
import { StyleSheet, css } from "aphrodite/no-important";
import "./searchBox.css";

const styles = StyleSheet.create({
  heading: {
    color: "#444444",
    fontWeight: "bold",
    marginBottom: "10px !important",
    textTransform: "uppercase",
    fontSize: 45
  },

  searchInput: {
    padding: "7px 25px 7px 7px",
    inset: "none",
    borderStyle: "none",
    borderRadius: "20px"
  },

  searchContainer: {
    border: "solid 1px grey",
    textIndent: "20px",
    borderRadius: "20px",
    background: "white"
  },
  searchWrapper: {
    borderBottom: "2px solid lightgrey",
    paddingBottom: 20
  },
  SearchListWrapper: {
    padding: "5px 5px 1px 10px",
    fontSize: 13,
    width: 120,
    boxShadow: "0 0 10px -3px grey"
  },

  SearchListItem: {
    borderBottom: "1px solid lightgrey",
    padding: "3px 0px",
    cursor: "pointer"
  },
  searchBox: {
    listStyle: "none",
    ":nth-child(1n) > :last-child": {
      border: "none"
    },
    itemIsActive: {
      color: "#3EB64A !important",
      padding: "3px 0px",
      cursor: "pointer"
    }
  }
});

class SearchBox extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      searchBoxData: this.props.searchBoxData
    };
  }

  handleClick = e => {
    this.setState({ target: e.target, show: !this.state.show });
  };
  UNSAFE_componentWillUnmount() {
    this.setItem(0);
  }
  setItem = index => {
    const merged = this.state.searchBoxData;
    if (!merged[index].isActive) {
      merged[index].isActive = !this.state.searchBoxData[index].isActive;
    }
    merged.forEach((item, rowIndex) => {
      return index !== rowIndex ? (item.isActive = false) : null;
    });
    this.setState({ searchBoxData: merged, show: false }, this.flterFunc);
  };

  flterFunc = () => {
    const { searchBoxData } = this.state;
    const filter = searchBoxData.find(item => item.isActive === true).name;
    this.props.filterFunction(filter);
  };

  render() {
    const { searchBoxData } = this.state;
    return (
      <Fragment>
        <Row horizontal="start">
          <h1 className={css(styles.heading)}>{this.props.heading}</h1>
        </Row>
        <Row
          wrap
          horizontal="space-between"
          vertical="center"
          breakpoints={{ 768: "column" }}
          className={css(styles.searchWrapper)}
        >
          <h6 style={{ cursor: "pointer" }} onClick={this.handleClick}>
            <span className="p-dark font-weight-bold">
              {searchBoxData.find(item => item.isActive === true).name}
            </span>
            <FontAwesomeIcon
              icon={faSortDown}
              style={{ fontSize: 22 }}
              className="p-dark ml-1"
            />
          </h6>
          <Overlay
            show={this.state.show}
            target={this.state.target}
            placement="bottom"
          >
            <Popover id="popover-contained">
              <div className={css(styles.SearchListWrapper)}>
                <ul className={css(styles.searchBox)}>
                  {this.props.searchBoxData.map((item, index) => (
                    <li
                      className={`${
                        item.isActive
                          ? "searchItemActive"
                          : css(styles.SearchListItem)
                      }`}
                      onClick={() => this.setItem(index)}
                    >
                      {item.name}
                      {item.isActive && (
                        <FontAwesomeIcon icon={faCheck} className="ml-3" />
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </Popover>
          </Overlay>
          {this.props.isSearchInput && (
            <div className={css(styles.searchContainer)}>
              <input
                onChange={this.props.onChange}
                type="text"
                name="search"
                placeholder="Search Restaurants"
                className={css(styles.searchInput)}
              />
            </div>
          )}
        </Row>
      </Fragment>
    );
  }
}

export default SearchBox;
