import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import UserTable from "components/UserTable/UserTable";
import { StyleSheet, css } from "aphrodite/no-important";
import { Row } from "simple-flexbox";

import {
  getUsersMiddleware,
  changeUserStatusMiddleware
} from "store/users/users.middleware";
import Loader from "components/Loader/Loader";

const styles = StyleSheet.create({
  searchInput: {
    padding: "7px",
    inset: "none",
    borderStyle: "none",
    borderRadius: "20px"
  },

  searchContainer: {
    border: "solid 1px grey",
    textIndent: "20px",
    borderRadius: "20px",
    background: "white"
  },
  heading: {
    color: "#444444",
    fontWeight: "bold",
    marginBottom: "10px !important",
    textTransform: "uppercase",
    fontSize: 45
  }
});

class Users extends Component {
  componentDidMount() {
    const { getUsers, token } = this.props;
    getUsers({ token });
  }
  changeUserStatus = data => {
    const { token, changeStatus } = this.props;
    changeStatus({ token, userId: data.userId, status: data.newStatus });
  };
  componentDidUpdate(prevProps) {
    const { users, token, getUsers } = this.props;
    if (prevProps.users !== users) {
      if (users.status === "change user status success") {
        getUsers({ token });
      }
    }
  }
  searchUsers = event => {
    const query = event.target.value;
    const { token, getUsers } = this.props;
    if (query.length) {
      query.length >= 3 && getUsers({ token, query });
    } else {
      getUsers({ token });
    }
  };
  render() {
    const { users } = this.props;
    return (
      <Fragment>
        <Row
          wrap
          horizontal="space-between"
          vertical="center"
          breakpoints={{ 768: "column" }}
          style={{ borderBottom: "2px solid lightgrey", paddingBottom: 10 }}
        >
          <Row horizontal="start">
            <h1 className={css(styles.heading)}>Users</h1>
          </Row>
          <div className={css(styles.searchContainer)}>
            <input
              onChange={this.searchUsers}
              type="text"
              name="search"
              placeholder="Search Users"
              className={css(styles.searchInput)}
            />
          </div>
        </Row>
        <UserTable
          data={users?.users ?? []}
          isUsersTable={true}
          forwardPath={"/users/information"}
          changeStatus={this.changeUserStatus}
        />
        {!!users.isloading && <Loader />}
      </Fragment>
    );
  }
}
const mapStateToProps = ({ users, admin: { token } }) => ({ users, token });

const mapDispatchToProps = dispatch => ({
  getUsers: data => dispatch(getUsersMiddleware(data)),
  changeStatus: data => dispatch(changeUserStatusMiddleware(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(Users);
