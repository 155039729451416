import React from "react";
import "./checkbox.css";

const Checkbox = props => {
  return (
    <div>
      <input
        className="inp-cbx"
        id="evening"
        type="checkbox"
        style={{ display: "none" }}
        value={props.value}
        checked={props.checked}
        onChange={props.onChange}
      />
      <label className="cbx" htmlFor="evening">
        <span>
          <svg width="12px" height="10px">
            <use xlinkHref="#check" />
          </svg>
        </span>
      </label>
      {/*SVG Sprites*/}
      <svg className="inline-svg">
        <symbol id="check" viewBox="0 0 12 10">
          <polyline points="1.5 6 4.5 9 10.5 1" />
        </symbol>
      </svg>
    </div>
  );
};

export default Checkbox;
