import axios from "axios";
import Path from "Config/Path";
import {
  getManagersSuccess,
  getManagersRequest,
  getManagersFailure,
  changeManagerStatusRequest,
  changeManagerStatusSuccess,
  changeManagerStatusFailure,
} from "./managers.actions";

export const getManagersMiddleware = (data) => {
  return async (dispatch) => {
    const params = {};
    data.query && (params.fullName = data.query);
    try {
      dispatch(getManagersRequest());
      const response = await axios.get(Path.GET_MANAGERS, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.token}`,
        },
        params,
      });
      dispatch(getManagersSuccess(response.data.Managers));
    } catch (error) {
      dispatch(
        getManagersFailure({
          success: false,
          errorMessage: "Something went wrong please try again",
        })
      );
    }
  };
};

export const changeManagerStatusMiddleware = (data) => {
  return async (dispatch) => {
    try {
      dispatch(changeManagerStatusRequest());
      await axios.put(
        Path.CHANGE_MANAGER_STATUS,
        { manager: data.managerId, status: data.status },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      dispatch(changeManagerStatusSuccess());
    } catch (error) {
      dispatch(
        changeManagerStatusFailure({
          success: false,
          errorMessage: "Something went wrong please try again",
        })
      );
    }
  };
};
