import React, { Fragment, Component } from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite/no-important";
import { connect } from "react-redux";
import { faChevronDown, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select, TimePicker } from "antd";
import moment from 'moment';
import { weekDays, itemTypes, days } from "./data";
import RadionButton from "components/RadioButton/RadionButton";
import Checkbox from "components/Checkbox/Checkbox";
import "./CustomNotifications.css";
import UserTable from "components/UserTable/UserTable";
import {
  getrestaurantsMiddleware,
  getrestaurantBranchessMiddleware,
  getrestaurantMenuMiddleware,
  getrestaurantGiftCardsMiddleware,
  getrestaurantofferMiddleware,
  getrestaurantRewardsMiddleware
} from "store/restaurants/restaurants.middleware";
import {
  sendNotificationMiddleware,
  getUserNotificationMiddleware
} from "store/notifications/notification.middleware";
import Loader from "components/Loader/Loader";

const { Option } = Select;

const styles = StyleSheet.create({
  notificationWrapper: {
    backgroundColor: "white",
    borderRadius: 5,
    padding: 25,
    boxShadow: "0 0 10px -3px grey",
    width: "80%",
    "@media (max-width: 768px)": {
      width: "100%",
      overflow: "hidden",
      boxShadow: "none",
      padding: 5
    }
  },
  fontBolder: {
    fontWeight: 900,
    paddingTop: 10
  },
  fontLink: {
    fontWeight: "bold",
    paddingTop: 15,
    marginLeft: "20px",
    color: 'rgb(62, 182, 74) !important',
    ":hover": {
      textDecoration: 'underline'
    },
    cursor: 'pointer'
  },
  Dropdown: {
    padding: 10,
    width: "45%",
    borderRadius: 5,
    textIndent: 10,
    marginTop: 13,
    border: "1px solid lightgrey"
  },

  smallDropdown: {
    padding: 10,
    width: "30%",
    borderRadius: 5,
    textIndent: 10,
    border: "1px solid lightgrey"
  },

  iconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 17,
    width: 17,
    borderRadius: "50%",
    backgroundColor: "lightGrey"
  },
  inputField: {
    borderRadius: 5,
    border: "1px solid lightgrey",
    padding: 10,
    textIndent: 10,
    marginTop: 13,
    width: "100%"
  },
  smallInputField: {
    borderRadius: 5,
    border: "1px solid lightgrey",
    padding: 10,
    textIndent: 10,
    marginTop: 13,
    width: "30%"
  },

  radiusField: {
    borderRadius: 5,
    border: "1px solid lightgrey",
    padding: 10,
    textIndent: 10,
    width: "30%"
  },

  mediumInputField: {
    borderRadius: 5,
    border: "1px solid lightgrey",
    padding: 10,
    textIndent: 10,
    width: "55%"
  },
  select: {
    borderRadius: 5,
    border: "1px solid #3EB64A",
    padding: 10,
    width: "30%",
    color: "#3EB64A",
    textAlign: "center",
    cursor: "pointer"
  },
  selectTime: {
    borderRadius: 5,
    border: "1px solid #AFAFAF",
    padding: 10,
    textIndent: 10,
    width: "45%"
  },
  weekDays: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ":nth-child(1n) > :first-child": {
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5
    },

    ":nth-child(1n) > :last-child": {
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5
    }
  },

  textMuted: {
    color: "#AFAFAF"
  }
});

class CustomNotifications extends Component {
  state = {
    notification: {
      title: "",
      body: "",
      restaurant: "",
      branch: "",
      itemType: "",
      item: ""
    },
    city: "",
    state: "",
    zipCode: "",
    orders: "",
    dob: "",
    time: "",
    isScheduleNotification: true,
    basedOnUserLocation: "isUserLocationTrue",
    orderFromAnyRestaurant: "isAnyRestaurantTrue",
    basedOnDob: "isBaseOnDobTrue",
    basedOnRestaurantLocation: "isRestaurantLocationTrue",
    basedOnItemOrder: "isItemOrderTrue",
    scheduleNotfications: "isScheduleNotificationTrue",
    isRepeatEveryDay: false,
    weekDays: weekDays,
    day: [],
    restaurantLocation: {
      restaurant: "",
      branch: "",
      radius: ""
    },
    itemOrder: {
      restaurant: "",
      itemType: "",
      item: ""
    }
  };

  componentDidMount() {
    const { token } = this.props;
    this.props.getRestaurants({ token });
  }

  UNSAFE_componentWillMount() {
    this.timer = null;
  }

  setItem = (index, tabName) => {

    const merged = this.state.weekDays;
    merged[index].isActive = !this.state.weekDays[index].isActive;
    const checkIsActive = merged.every(item => item.isActive);
    if (checkIsActive) {
      this.setState({ weekDays: merged, isRepeatEveryDay: true });
    } else {
      this.setState({ weekDays: merged, isRepeatEveryDay: false });
    }
  };

  itemName = itemType => {
    const { menu, giftCards, offers, rewards } = this.props;
    switch (itemType) {
      case "menu":
        return (
          <Fragment>
            {menu &&
              menu.map(item => (
                <Option key={item._id} value={item._id}>
                  {item.title}
                </Option>
              ))}
          </Fragment>
        );
      case "giftCard":
        return (
          <Fragment>
            {giftCards &&
              giftCards.map(item => (
                <Option key={item._id} value={item._id}>
                  {item.title}
                </Option>
              ))}
          </Fragment>
        );

      case "offer":
        return (
          <Fragment>
            {offers &&
              offers.map(item => (
                <Option key={item._id} value={item._id}>
                  {item.title}
                </Option>
              ))}
          </Fragment>
        );

      case "reward":
        return (
          <Fragment>
            {rewards &&
              rewards.map(item => (
                <Option key={item._id} value={item._id}>
                  {item.title}
                </Option>
              ))}
          </Fragment>
        );
      default:
        return null;
    }
  };

  handleChange = event => {
    const { notification } = this.state;
    notification[event.target.name] = event.target.value;
    this.setState({ notification });
  };

  handleFieldChange = event => {
    const selectedValue = event.target.value;
    const selectName = event.target.name;
    this.setState({ [event.target.name]: event.target.value });
    switch (selectName) {
      case "basedOnDob":
        if (selectedValue === "isBaseOnDobFalse") {
          this.setState({ dob: "" });
        }
        break;
      case "basedOnUserLocation":
        if (selectedValue === "isUserLocationFalse") {
          this.setState({ city: "", state: "", zipCode: "" });
        }
        break;
      case "orderFromAnyRestaurant":
        if (selectedValue === "isAnyRestaurantFalse") {
          this.setState({ orders: "" });
        }
        break;
      case "basedOnRestaurantLocation":
        if (selectedValue === "isRestaurantLocationFalse") {
          this.setState({
            restaurantLocation: {
              restaurant: "",
              branch: "",
              radius: ""
            }
          });
        }
        break;
      case "basedOnItemOrder":
        if (selectedValue === "isItemOrderFalse") {
          this.setState({
            itemOrder: {
              restaurant: "",
              itemType: "",
              item: ""
            }
          });
        }
        break;

      case "scheduleNotfications":
        if (selectedValue === "isScheduleNotificationFalse") {
          this.setState({
            time: ""
          });
        }
        break;
      default:
        return null;
    }
  };

  handleRestaurantSelect = value => {
    const { token } = this.props;
    const { notification } = this.state;
    notification.restaurant = value;
    notification.branch = "";
    const restaurantId = value;
    this.setState({
      notification
    });
    this.props.getBranches({ token, restaurantId });
  };

  handleDropDownSelect = (value, property) => {
    const { notification } = this.state;
    notification[property] = value;
    this.setState({ notification });
  };

  handleRestaurantSelectInLocation = value => {
    const { token } = this.props;
    const { restaurantLocation } = this.state;
    const restaurantId = value;
    restaurantLocation.restaurant = value;
    restaurantLocation.branch = "";
    this.setState({
      restaurantLocation
    });
    this.props.getBranches({
      token,
      restaurantId,
      branchesForRestaurantLocation: true
    });
  };

  handleBranchesSelectForLocation = value => {
    const { restaurantLocation } = this.state;
    restaurantLocation.branch = value;
    this.setState({
      restaurantLocation
    });
  };

  handleRadius = event => {
    const { restaurantLocation } = this.state;
    restaurantLocation.radius = event.target.value;
    this.setState({
      restaurantLocation
    });
  };

  handleRestaurantSelectForOrder = value => {
    const { itemOrder } = this.state;
    itemOrder.restaurant = value;
    this.setState(
      {
        itemOrder
      },
      () => {
        this.triggerChange();
      }
    );
  };

  handleItemTypeSelectInOrder = itemSelect => {
    const {
      token,
      getMenuItems,
      getOffers,
      getGiftCards,
      getRewards
    } = this.props;
    const { itemOrder } = this.state;
    itemOrder.itemType = itemSelect;
    itemOrder.item = "";
    this.setState({ itemOrder }, () => {
      this.triggerChange();
      const {
        itemOrder: { itemType, restaurant }
      } = this.state;
      const restaurantId = restaurant;
      if (itemType === "menu") {
        return getMenuItems({ token, restaurantId });
      } else if (itemType === "giftCard") {
        return getGiftCards({ token, restaurantId });
      } else if (itemType === "offer") {
        return getOffers({ token, restaurantId });
      } else if (itemType === "reward") {
        return getRewards({ token, restaurantId });
      }
    });
  };

  handleItemTypeSelect = itemSelect => {
    const {
      token,
      getMenuItems,
      getOffers,
      getGiftCards,
      getRewards
    } = this.props;
    const { notification } = this.state;
    notification.itemType = itemSelect;
    notification.item = "";
    this.setState({ notification }, () => {
      const {
        notification: { itemType, restaurant }
      } = this.state;
      const restaurantId = restaurant;
      if (itemType === "menu") {
        return getMenuItems({ token, restaurantId });
      } else if (itemType === "giftCard") {
        return getGiftCards({ token, restaurantId });
      } else if (itemType === "offer") {
        return getOffers({ token, restaurantId });
      } else if (itemType === "reward") {
        return getRewards({ token, restaurantId });
      }
    });
  };

  handleItemNameSelectInOrder = value => {
    const { itemOrder } = this.state;
    itemOrder.item = value;
    this.setState({ itemOrder }, () => {
      this.triggerChange();
    });
  };

  handleCheck = event => {
    const { weekDays } = this.state;
    weekDays.forEach(item => {
      item.isActive = true;
      this.setState(ps => ({
        weekDays,
        isRepeatEveryDay: !ps.isRepeatEveryDay
      }));
    });
  };

  handleCheckFalse = event => {
    const { weekDays } = this.state;
    weekDays.forEach(item => {
      item.isActive = false;
      this.setState(ps => ({
        weekDays,
        isRepeatEveryDay: !ps.isRepeatEveryDay
      }));
    });
  };

  sendNotification = () => {
    const {
      notification,
      city,
      state,
      zipCode,
      orders,
      restaurantLocation,
      itemOrder,
      time,
      weekDays,
      scheduleNotfications
    } = this.state;
    const { token } = this.props;
    const filterWeekDays = weekDays
      // eslint-disable-next-line array-callback-return
      .map((item, index) => {
        if (item.isActive) {
          return index;
        }
      })
      .filter(Boolean);
    const checkScheduleNotification =
      scheduleNotfications === "isScheduleNotificationTrue" ? true : false;
    const data = {
      notification,
      city,
      state,
      zipCode,
      orders,
      restaurantLocation,
      itemOrder,
      time: time ? moment(time).format('HH:mm') : "",
      day: filterWeekDays,
      isScheduleNotification: checkScheduleNotification
    };
    this.props.sendNotification({ token, data });
    weekDays.forEach(item => (item.isActive = false));
    this.setState({
      notification: {
        title: "",
        body: "",
        restaurant: "",
        branch: "",
        itemType: "",
        item: ""
      },
      city: "",
      state: "",
      zipCode: "",
      orders: "",
      dob: "",
      time: "",
      day: [],
      restaurantLocation: {
        restaurant: "",
        branch: "",
        radius: ""
      },
      itemOrder: {
        restaurant: "",
        itemType: "",
        item: ""
      },
      weekDays: weekDays,
      isRepeatEveryDay: false,
      isScheduleNotification: true,
      basedOnUserLocation: "isUserLocationTrue",
      orderFromAnyRestaurant: "isAnyRestaurantTrue",
      basedOnDob: "isBaseOnDobTrue",
      basedOnRestaurantLocation: "isRestaurantLocationTrue",
      basedOnItemOrder: "isItemOrderTrue",
      scheduleNotfications: "isScheduleNotificationTrue"
    });
  };

  handleChangeToGetUser = event => {
    clearTimeout(this.timer);
    this.setState({ [event.target.name]: event.target.value });
    this.timer = setTimeout(this.triggerChange, 3000);
  };

  triggerChange = () => {
    const { token } = this.props;
    const { city, state, dob, zipCode, orders, itemOrder } = this.state;
    const data = { city, state, dob, zipCode, orders, itemOrder };
    this.props.getNotification({ token, data });
  };

  render() {
    const {
      notification,
      orders,
      basedOnUserLocation,
      orderFromAnyRestaurant,
      dob,
      basedOnDob,
      basedOnRestaurantLocation,
      basedOnItemOrder,
      scheduleNotfications,
      isRepeatEveryDay,
      weekDays,
      itemOrder,
      city,
      state,
      zipCode,
      time,
      Day,
      restaurantLocation
    } = this.state;
    const {
      restaurants: { restaurants },
      branches,
      branchesForLocation,
      isloading,
      users
    } = this.props;
    return (
      <div className="flexer">
        <Column
          horizontal="center"
          vertical="center"
          className={css(styles.notificationWrapper)}
        >
          <div style={{
            display: "flex",
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: "61%",
            alignSelf: "flex-end"
          }}>
            <h4 className={css(styles.fontBolder)}>Send Custom Notifications</h4>
            <p onClick={() => {
              this.props.history.push("/allnotifications");
            }} className={css(styles.fontLink)}>View all notifications</p>
          </div>

          <Row
            horizontal="space-between"
            vertical="center"
            className="w-100 mt-5"
            wrap
          >
            <Select
              size="large"
              dropdownClassName={css(styles.Dropdown)}
              style={{ width: "45%", borderRadius: 5, marginTop: 13 }}
              onChange={this.handleRestaurantSelect}
              value={notification.restaurant || "Select Restaurants"}
              suffixIcon={
                <div className={css(styles.iconWrapper)}>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    color="#444444"
                    size="sm"
                  />
                </div>
              }
            >
              {restaurants &&
                Boolean(restaurants.length) &&
                restaurants.map(item => (
                  <Option key={item._id} value={item._id}>
                    {item.name}
                  </Option>
                ))}
            </Select>

            <Select
              size="large"
              dropdownClassName={css(styles.Dropdown)}
              style={{ width: "45%", borderRadius: 5, marginTop: 13 }}
              value={notification.branch || "Select Branches Name"}
              onChange={value => this.handleDropDownSelect(value, "branch")}
              suffixIcon={
                <div className={css(styles.iconWrapper)}>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    color="#444444"
                    size="sm"
                  />
                </div>
              }
            >
              {branches &&
                Boolean(branches.length) &&
                branches.map(item => (
                  <Option key={item._id} value={item._id}>
                    {item.address}
                  </Option>
                ))}
            </Select>

            <Select
              size="large"
              dropdownClassName={css(styles.Dropdown)}
              style={{ width: "45%", borderRadius: 5, marginTop: 13 }}
              onChange={value => this.handleItemTypeSelect(value)}
              value={notification.itemType || "Select Item Types"}
              suffixIcon={
                <div className={css(styles.iconWrapper)}>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    color="#444444"
                    size="sm"
                  />
                </div>
              }
            >
              {itemTypes &&
                Boolean(itemTypes.length) &&
                itemTypes.map(item => (
                  <Option key={item.type} value={item.value}>
                    {item.type}
                  </Option>
                ))}
            </Select>

            <Select
              size="large"
              dropdownClassName={css(styles.Dropdown)}
              style={{ width: "45%", borderRadius: 5, marginTop: 13 }}
              onChange={value => this.handleDropDownSelect(value, "item")}
              value={notification.item || "Select Item Name"}
              suffixIcon={
                <div className={css(styles.iconWrapper)}>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    color="#444444"
                    size="sm"
                  />
                </div>
              }
            >
              {this.itemName(notification.itemType)}
            </Select>
          </Row>
          <Row horizontal="center" vertical="center" className="w-100 " wrap>
            <input
              type="text"
              name="title"
              placeholder="Title *"
              value={notification.title}
              className={css(styles.inputField)}
              onChange={this.handleChange}
            />
            <textarea
              type="text"
              value={notification.body}
              name="body"
              placeholder="Description *"
              className={css(styles.inputField)}
              onChange={this.handleChange}
              rows="5"
            />
          </Row>
          <Row
            horizontal="start"
            vertical="center"
            className="w-100 mt-5"
            wrap
            breakpoints={{ 768: "column" }}
          >
            <Row
              horizontal="space-between"
              vertical="center"
              style={{ width: "42%" }}
              breakpoints={{ 768: "column" }}
            >
              <h6 className="font-weight-bolder">Based on User's location</h6>
              <RadionButton
                valueOne={"isUserLocationTrue"}
                valueTwo={"isUserLocationFalse"}
                isSelectedOne={basedOnUserLocation === "isUserLocationTrue"}
                isSelectedTwo={basedOnUserLocation === "isUserLocationFalse"}
                name={"basedOnUserLocation"}
                handleFieldChange={event =>
                  this.handleFieldChange(event, "location")
                }
                idOne={"isUserLocationTrue"}
                idTwo={"isUserLocationFalse"}
              />
            </Row>
            {basedOnUserLocation === "isUserLocationTrue" && <Row className="w-100 mt-3" horizontal="space-between">
              <input
                type="text"
                name="city"
                required={false}
                placeholder="City"
                value={city}
                id="city"
                disabled={basedOnUserLocation === "isUserLocationFalse"}
                onChange={event => this.handleChangeToGetUser(event)}
                className={css(styles.smallInputField)}
              />

              <input
                type="text"
                name="state"
                required={false}
                placeholder="State"
                value={state}
                disabled={basedOnUserLocation === "isUserLocationFalse"}
                onChange={event => this.handleChangeToGetUser(event)}
                className={css(styles.smallInputField)}
              />
              <input
                type="text"
                name="zipCode"
                required={false}
                placeholder="Enter Zipcode"
                value={zipCode}
                disabled={basedOnUserLocation === "isUserLocationFalse"}
                onChange={event => this.handleChangeToGetUser(event)}
                className={css(styles.smallInputField)}
              />
            </Row>}
          </Row>
          <Row horizontal="start" vertical="center" className="w-100 mt-4" wrap>
            <Row horizontal="space-between" vertical="center" className="w-100">
              <div className="flexer-space-between" style={{ width: "42%" }}>
                <h6 className="font-weight-bolder">Base on Date of birth</h6>
                <RadionButton
                  valueOne={"isBaseOnDobTrue"}
                  valueTwo={"isBaseOnDobFalse"}
                  isSelectedOne={basedOnDob === "isBaseOnDobTrue"}
                  isSelectedTwo={basedOnDob === "isBaseOnDobFalse"}
                  name={"basedOnDob"}
                  handleFieldChange={this.handleFieldChange}
                  idOne={"isBaseOnDobTrue"}
                  idTwo={"isBaseOnDobFalse"}
                />
              </div>
              {basedOnDob === "isBaseOnDobTrue" && <input
                type="date"
                name="dob"
                required="false"
                placeholder="Date Of Birth"
                className={css(styles.mediumInputField)}
                onChange={event => this.handleChangeToGetUser(event)}
                value={dob}
                disabled={basedOnDob === "isBaseOnDobFalse"}
              />}
            </Row>
          </Row>
          <Row horizontal="start" vertical="center" className="w-100 mt-4" wrap>
            <Row horizontal="space-between" vertical="center" className="w-100">
              <div className="flexer-space-between" style={{ width: "42%" }}>
                <h6 className="font-weight-bolder">
                  Order from any restaurant
                </h6>
                <RadionButton
                  valueOne={"isAnyRestaurantTrue"}
                  valueTwo={"isAnyRestaurantFalse"}
                  isSelectedOne={
                    orderFromAnyRestaurant === "isAnyRestaurantTrue"
                  }
                  isSelectedTwo={
                    orderFromAnyRestaurant === "isAnyRestaurantFalse"
                  }
                  name={"orderFromAnyRestaurant"}
                  handleFieldChange={this.handleFieldChange}
                  idOne={"isAnyRestaurantTrue"}
                  idTwo={"isAnyRestaurantFalse"}
                />
              </div>
              {orderFromAnyRestaurant === "isAnyRestaurantTrue" && <input
                type="text"
                name="orders"
                required="false"
                value={orders}
                onChange={event => this.handleChangeToGetUser(event)}
                placeholder="Number Of Orders"
                className={css(styles.mediumInputField)}
                disabled={orderFromAnyRestaurant === "isAnyRestaurantFalse"}
              />}
            </Row>
          </Row>
          <Row horizontal="start" vertical="center" className="w-100 mt-4" wrap>
            <Row
              horizontal="space-between"
              vertical="center"
              style={{ width: "42%" }}
            >
              <h6 className="font-weight-bolder">
                Based on Restaurant's location
              </h6>
              <RadionButton
                valueOne={"isRestaurantLocationTrue"}
                valueTwo={"isRestaurantLocationFalse"}
                isSelectedOne={
                  basedOnRestaurantLocation === "isRestaurantLocationTrue"
                }
                isSelectedTwo={
                  basedOnRestaurantLocation === "isRestaurantLocationFalse"
                }
                name={"basedOnRestaurantLocation"}
                handleFieldChange={this.handleFieldChange}
                idOne={"isRestaurantLocationTrue"}
                idTwo={"isRestaurantLocationFalse"}
              />
            </Row>
          </Row>

          {basedOnRestaurantLocation === "isRestaurantLocationTrue" && <Row
            horizontal="space-between"
            vertical="center"
            className="w-100 mt-4"
            wrap
          >
            <Select
              size="large"
              dropdownClassName={css(styles.Dropdown)}
              value={restaurantLocation.restaurant || "Select Resutarants"}
              style={{ width: "30%", borderRadius: 5 }}
              onChange={this.handleRestaurantSelectInLocation}
              disabled={
                basedOnRestaurantLocation === "isRestaurantLocationFalse"
              }
              suffixIcon={
                <div className={css(styles.iconWrapper)}>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    color="#444444"
                    size="sm"
                  />
                </div>
              }
            >
              {restaurants &&
                Boolean(restaurants.length) &&
                restaurants.map(item => (
                  <Option key={item._id} value={item._id}>
                    {item.name}
                  </Option>
                ))}
            </Select>

            <Select
              size="large"
              dropdownClassName={css(styles.Dropdown)}
              value={restaurantLocation.branch || "Select Branches Name"}
              style={{ width: "30%", borderRadius: 5 }}
              onChange={value => this.handleBranchesSelectForLocation(value)}
              disabled={
                basedOnRestaurantLocation === "isRestaurantLocationFalse"
              }
              suffixIcon={
                <div className={css(styles.iconWrapper)}>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    color="#444444"
                    size="sm"
                  />
                </div>
              }
            >
              {branchesForLocation &&
                Boolean(branchesForLocation.length) &&
                branchesForLocation.map(item => (
                  <Option key={item._id} value={item._id}>
                    {item.address}
                  </Option>
                ))}
            </Select>
            <input
              type="text"
              name="Radius"
              required={false}
              value={restaurantLocation.radius}
              placeholder="Radius"
              className={css(styles.radiusField)}
              disabled={
                basedOnRestaurantLocation === "isRestaurantLocationFalse"
              }
              onChange={this.handleRadius}
            />
          </Row>}

          <Row horizontal="start" vertical="center" className="w-100 mt-4" wrap>
            <Row
              horizontal="space-between"
              vertical="center"
              style={{ width: "42%" }}
            >
              <h6 className="font-weight-bolder">Based on item order</h6>
              <RadionButton
                valueOne={"isItemOrderTrue"}
                valueTwo={"isItemOrderFalse"}
                isSelectedOne={basedOnItemOrder === "isItemOrderTrue"}
                isSelectedTwo={basedOnItemOrder === "isItemOrderFalse"}
                name={"basedOnItemOrder"}
                handleFieldChange={this.handleFieldChange}
                idOne={"isItemOrderTrue"}
                idTwo={"isItemOrderFalse"}
              />
            </Row>
          </Row>

          {basedOnItemOrder === "isItemOrderTrue" && <Row
            horizontal="space-between"
            vertical="center"
            className="w-100 mt-4"
            wrap
          >
            <Select
              size="large"
              dropdownClassName={css(styles.Dropdown)}
              value={itemOrder.restaurant || "Select Restaurants"}
              style={{ width: "30%", borderRadius: 5 }}
              onChange={this.handleRestaurantSelectForOrder}
              disabled={basedOnItemOrder === "isItemOrderFalse"}
              suffixIcon={
                <div className={css(styles.iconWrapper)}>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    color="#444444"
                    size="sm"
                  />
                </div>
              }
            >
              {restaurants &&
                Boolean(restaurants.length) &&
                restaurants.map(item => (
                  <Option key={item._id} value={item._id}>
                    {item.name}
                  </Option>
                ))}
            </Select>

            <Select
              size="large"
              dropdownClassName={css(styles.Dropdown)}
              value={itemOrder.itemType || "Select Item Type"}
              style={{ width: "30%", borderRadius: 5 }}
              onChange={value => this.handleItemTypeSelectInOrder(value)}
              disabled={basedOnItemOrder === "isItemOrderFalse"}
              suffixIcon={
                <div className={css(styles.iconWrapper)}>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    color="#444444"
                    size="sm"
                  />
                </div>
              }
            >
              {itemTypes &&
                Boolean(itemTypes.length) &&
                itemTypes.map(item => (
                  <Option key={item.type} value={item.value}>
                    {item.type}
                  </Option>
                ))}
            </Select>

            <Select
              size="large"
              dropdownClassName={css(styles.Dropdown)}
              value={itemOrder.item || "Select Item Name"}
              style={{ width: "30%", borderRadius: 5 }}
              onChange={value => this.handleItemNameSelectInOrder(value)}
              disabled={basedOnItemOrder === "isItemOrderFalse"}
              suffixIcon={
                <div className={css(styles.iconWrapper)}>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    color="#444444"
                    size="sm"
                  />
                </div>
              }
            >
              {this.itemName(itemOrder.itemType)}
            </Select>
          </Row>}
          <Row horizontal="start" vertical="center" className="w-100 mt-4" wrap>
            <Row
              horizontal="space-between"
              vertical="center"
              style={{ width: "42%" }}
            >
              <h6 className="font-weight-bolder">Schedule notifications</h6>
              <RadionButton
                valueOne={"isScheduleNotificationTrue"}
                valueTwo={"isScheduleNotificationFalse"}
                isSelectedOne={
                  scheduleNotfications === "isScheduleNotificationTrue"
                }
                isSelectedTwo={
                  scheduleNotfications === "isScheduleNotificationFalse"
                }
                name={"scheduleNotfications"}
                handleFieldChange={this.handleFieldChange}
                idOne={"isScheduleNotificationTrue"}
                idTwo={"isScheduleNotificationFalse"}
              />
            </Row>
            {scheduleNotfications === "isScheduleNotificationTrue" && <Row className="w-100 mt-4" horizontal="space-between">
              {/* <input
                type="text"
                name="Day"
                required={false}
                placeholder="Enter Day"
                disabled={
                  scheduleNotfications === "isScheduleNotificationFalse"
                }
                className={css(styles.selectTime)}
                onChange={event =>
                  this.setState({ [event.target.name]: event.target.value })
                }
              /> */}
              <Select
              size="large"
              dropdownClassName={css(styles.Dropdown)}
              style={{ width: "45%", borderRadius: 5, marginTop: 13 }}
              onChange={value => this.setItem(value)}
              value={Day || "Select Day"}
              suffixIcon={
                <div className={css(styles.iconWrapper)}>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    color="#444444"
                    size="sm"
                  />
                </div>
              }
            >
              {days &&
                Boolean(days.length) &&
                days.map(item => (
                  <Option key={item.day} value={item.value}>
                    {item.day}
                  </Option>
                ))}
            </Select>
              {/* <input
                type="time"
                name="time"
                required={false}
                placeholder="Select Time"
                disabled={
                  scheduleNotfications === "isScheduleNotificationFalse"
                }
                value={time}
                className={css(styles.selectTime)}
                onChange={event =>
                  this.setState({ [event.target.name]: event.target.value })
                }
              /> */}
              <TimePicker
                value={time}
                name="time"
                required={false}
                placeholder="Select Time"
                disabled={
                  scheduleNotfications === "isScheduleNotificationFalse"
                }
                className={css(styles.selectTime)}
                onChange={time =>
                  this.setState({ "time": time })
                }
                defaultValue={moment('12:08', 'HH:mm')}
                format={'HH:mm'}
              />
            </Row>}
          </Row>

          <Row
            horizontal="space-between"
            vertical="center"
            className="w-100 mt-4"
            wrap
          >
            <Row
              horizontal="space-between"
              vertical="center"
              style={{ width: "42%" }}
            >
              <h6 className="font-weight-bolder">Repeat Everyday</h6>
              <Checkbox
                value={isRepeatEveryDay}
                onChange={
                  !isRepeatEveryDay
                    ? ev => this.handleCheck(ev)
                    : ev => this.handleCheckFalse(ev)
                }
                checked={isRepeatEveryDay}
              />
            </Row>
            <div className={css(styles.weekDays)}>
              {weekDays.map((item, index) => (
                <span
                  className={item.isActive ? "active-week-days" : "week-days"}
                  onClick={() => this.setItem(index)}
                >
                  {item.initial}
                </span>
              ))}
            </div>
          </Row>

          <Row
            horizontal="space-between"
            vertical="center"
            className="w-100 mt-4"
            wrap
          >
            <p className={css(styles.textMuted)}>
              <span className="font-weight-bold">{users.length}</span> user will
              be notified
            </p>
            <button
              type="submit"
              className="forget-password-btn"
              style={{ width: "25%" }}
              onClick={() => this.sendNotification()}
            >
              Send
              <FontAwesomeIcon
                className="mt-1 mr-3"
                style={{ float: "right" }}
                icon={faArrowRight}
              />
            </button>
          </Row>

          <Row
            wrap
            horizontal="space-between"
            vertical="center"
            breakpoints={{ 768: "column" }}
            className="w-100"
          >
            <UserTable
              data={users}
              isNotificationPage={true}
              forwardPath={"/users/information"}
            />
          </Row>
        </Column>
        {isloading && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = ({
  restaurants,
  admin: { token },
  notification: { message, isloading, users },
  restaurants: {
    branches,
    branchesForLocation,
    menu,
    giftCards,
    offers,
    rewards
  }
}) => ({
  restaurants,
  token,
  branches,
  menu,
  giftCards,
  offers,
  rewards,
  branchesForLocation,
  message,
  isloading,
  users
});

const mapDispatchToProps = dispatch => ({
  getBranches: data => dispatch(getrestaurantBranchessMiddleware(data)),
  getRestaurants: data => dispatch(getrestaurantsMiddleware(data)),
  getMenuItems: data => dispatch(getrestaurantMenuMiddleware(data)),
  getOffers: data => dispatch(getrestaurantofferMiddleware(data)),
  getGiftCards: data => dispatch(getrestaurantGiftCardsMiddleware(data)),
  getRewards: data => dispatch(getrestaurantRewardsMiddleware(data)),
  sendNotification: data => dispatch(sendNotificationMiddleware(data)),
  getNotification: data => dispatch(getUserNotificationMiddleware(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomNotifications);
