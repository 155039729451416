import axios from "axios";
import Path from "Config/Path";
import {
  getOrdersSuccess,
  getOrdersRequest,
  getOrdersFailure,
} from "./orders.actions";

export const getordersMiddleware = (data) => {
  return async (dispatch) => {
    const params = {
      //    populateRestaurant: true,
    };
    data.filter && (params.status = data.filter);
    try {
      dispatch(getOrdersRequest());
      const response = await axios.get(Path.GET_ORDERS, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.token}`,
        },
        params,
      });
      dispatch(getOrdersSuccess(response.data.Order));
    } catch (error) {
      dispatch(
        getOrdersFailure({
          success: false,
          errorMessage: "Something went wrong please try again",
        })
      );
    }
  };
};
