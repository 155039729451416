import React, { Component } from "react";
import "./radioButton.css";

class RadionButton extends Component {
  render() {
    return (
      <div className="radio-group">
        <input
          type="radio"
          className="radio-btn"
          id={this.props.idOne}
          name={this.props.name}
          onChange={this.props.handleFieldChange}
          value={this.props.valueOne}
          checked={this.props.isSelectedOne}
        />
        <label className="category-radio m-0" htmlFor={this.props.idOne}>
          Yes
        </label>

        <input
          type="radio"
          id={this.props.idTwo}
          onChange={this.props.handleFieldChange}
          name={this.props.name}
          value={this.props.valueTwo}
          checked={this.props.isSelectedTwo}
        />
        <label className="category-radio  m-0" htmlFor={this.props.idTwo}>
          No
        </label>
      </div>
    );
  }
}

export default RadionButton;
