import React, { Component } from 'react';
import Checkbox from '../../components/Checkbox/Checkbox';
import './BusinessInformation.css';
import NavBar from '../../components/NavBar/NavBar';

class BusinessInformation extends Component {
    render() {
        return (
            <>
            <NavBar/>
            <div className="container-fluid flexer">
                <div className="row vw-100 flexer">
                    <div className="col-lg-6 col-md-10 col-sm-10 info-container-card">

                        <div className="info-heading-wrapper">
                            <h1 className="info-heading">Business Information</h1>
                        </div>
                        <form className="flexer-column" style={{ width: '80%' }}>

                            <div style={{ width: '100%', columnCount: 2 }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox className='' />
                                    <label className="input-label">Sole Proprietor</label>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox />
                                    <label className="input-label">Corporation C</label>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox className='' />
                                    <label className="input-label">Corporation S</label>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox />
                                    <label className="input-label">Limited Liability</label>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox className='' />
                                    <label className="input-label">General Partnership</label>
                                </div>
                            </div>

                            <div className='business-info-input-field-wrapper w-100'>
                                <label className="input-label w-100">
                                    DBA NAME
                            </label>
                                <input
                                    //   onChange={this.handleChange}
                                    type="text"
                                    //   value={email}
                                    name="email"
                                    className="business-info-input-field w-100"
                                    
                                />
                            </div>

                            <div className='business-info-input-field-wrapper w-100'>
                                <label className="input-label w-100">
                                    CORPORATE NAME
                            </label>
                                <input
                                    //   onChange={this.handleChange}
                                    type="text"
                                    //   value={password}
                                    name=""
                                    className="business-info-input-field TB w-100"
                                    
                                />
                            </div>

                            <div className='business-info-input-field-wrapper w-100'>
                                <label className="input-label w-100">
                                    CONTACT NAME
                            </label>
                                <input
                                    //   onChange={this.handleChange}
                                    type="text"
                                    //   value={password}
                                    name=""
                                    className="business-info-input-field TB w-100"
                                    
                                />
                            </div>


                            <div className='business-info-input-field-wrapper' style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '48%' }}>
                                    <label className="input-label w-100">
                                        DBA PHONE
                                    </label>
                                    <input
                                        //   onChange={this.handleChange}
                                        type="text"
                                        //   value={password}
                                        name=""
                                        className="business-info-input-field TB w-100"
                                        
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <label className="input-label w-100">
                                        DBA FAX
                                    </label>
                                    <input
                                        //   onChange={this.handleChange}
                                        type="text"
                                        //   value={password}
                                        name=""
                                        className="business-info-input-field TB w-100"
                                        
                                    />
                                </div>
                            </div>

                            <div className='business-info-input-field-wrapper' style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '48%' }}>
                                    <label className="input-label w-100">
                                        DBA STREET
                                    </label>
                                    <input
                                        //   onChange={this.handleChange}
                                        type="text"
                                        //   value={password}
                                        name=""
                                        className="business-info-input-field TB w-100"
                                        
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <label className="input-label w-100">
                                        CITY
                                    </label>
                                    <select
                                        //   onChange={this.handleChange}
                                        //   value={password}
                                        name=""
                                        className="business-info-input-field TB w-100"
                                        
                                    >
                                        <option>Select City</option>
                                    </select>
                                </div>
                            </div>

                            <div className='business-info-input-field-wrapper' style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '48%' }}>
                                    <label className="input-label w-100">
                                        STATE
                                    </label>
                                    <select
                                        //   onChange={this.handleChange}
                                        //   value={password}
                                        name=""
                                        className="business-info-input-field TB w-100"
                                        
                                    >
                                        <option>Select State</option>
                                    </select>
                                </div>
                                <div style={{ width: '48%' }}>
                                    <label className="input-label w-100">
                                        ZIP CODE
                                    </label>
                                    <input
                                        //   onChange={this.handleChange}
                                        type="text"
                                        //   value={password}
                                        name=""
                                        className="business-info-input-field TB w-100"
                                        
                                    />
                                </div>
                            </div>



                            <div className='business-info-input-field-wrapper w-100'>
                                <label className="input-label w-100">
                                   EMAIL ADDRESS
                            </label>
                                <input
                                    //   onChange={this.handleChange}
                                    type="text"
                                    //   value={email}
                                    name="email"
                                    className="business-info-input-field w-100"
                                    
                                />
                            </div>

                            <div className='business-info-input-field-wrapper' style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '48%' }}>
                                    <label className="input-label w-100">
                                        YEAR ESTABLISHED
                                    </label>
                                    <input
                                        //   onChange={this.handleChange}
                                        type="text"
                                        //   value={password}
                                        name=""
                                        className="business-info-input-field TB w-100"
                                        
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <label className="input-label w-100">
                                        EIN  NUMBER
                                    </label>
                                    <input
                                        //   onChange={this.handleChange}
                                        type="text"
                                        //   value={password}
                                        name=""
                                        className="business-info-input-field TB w-100"
                                        
                                    />
                                </div>
                            </div>


                            <div className='business-info-input-field-wrapper w-100'>
                                <label className="input-label w-100">
                                    CURRENT OWNERSHIP
                            </label>
                                <input
                                    //   onChange={this.handleChange}
                                    type="text"
                                    //   value={email}
                                    name="email"
                                    className="business-info-input-field w-100"
                                    
                                />
                            </div>

                            <div className='business-info-input-field-wrapper' style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '48%' }}>
                                    <label className="input-label w-100">
                                        ITIN
                                    </label>
                                    <input
                                        //   onChange={this.handleChange}
                                        type="text"
                                        //   value={password}
                                        name=""
                                        className="business-info-input-field TB w-100"
                                        
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <label className="input-label w-100">
                                        SSN
                                    </label>
                                    <input
                                        //   onChange={this.handleChange}
                                        type="text"
                                        //   value={password}
                                        name=""
                                        className="business-info-input-field TB w-100"
                                        
                                    />
                                </div>
                            </div>

                        </form>
                        <div className="w-100" style={{display:'flex'}}>
                            <button
                                //   onClick={this.handleSubmit}
                                type="submit"
                                className="w-100 business-info-btn"
                            >
                                Save Progress
                            </button>
                            <button
                                //   onClick={this.handleSubmit}
                                type="submit"
                                className="w-100 business-info-btn"
                            >
                                Next
                            </button>

                        </div>
                    </div>
                </div>
            </div>
            </>
        );
    }
}


export default BusinessInformation;