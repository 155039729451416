import CardImage from "assets/img/taco-mesa.png";

export const offersAndPromotionsData = [
  {
    id: 1,
    image: CardImage,
    discount: "Flat 40%",
    description: "on Steak Picado try now!"
  },

  {
    id: 2,
    image: CardImage,
    discount: "Flat 40%",
    description: "on Steak Picado try now!"
  },

  {
    id: 3,
    image: CardImage,
    discount: "Flat 40%",
    description: "on Steak Picado try now!"
  },
  {
    id: 4,
    image: CardImage,
    discount: "Flat 40%",
    description: "on Steak Picado try now!"
  },

  {
    id: 5,
    image: CardImage,
    discount: "Flat 40%",
    description: "on Steak Picado try now!"
  },

  {
    id: 6,
    image: CardImage,
    discount: "Flat 40%",
    description: "on Steak Picado try now!"
  }
];
