import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite/no-important";
import { Row } from "simple-flexbox";
import {
  getManagersMiddleware,
  changeManagerStatusMiddleware
} from "./../../store/managers/managers.middleware";
import ManagersList from "components/ManagersList/ManagersList";
import Loader from "components/Loader/Loader";

const styles = StyleSheet.create({
  searchInput: {
    padding: "7px",
    inset: "none",
    borderStyle: "none",
    borderRadius: "20px"
  },

  searchContainer: {
    border: "solid 1px grey",
    textIndent: "20px",
    borderRadius: "20px",
    background: "white"
  },
  heading: {
    color: "#444444",
    fontWeight: "bold",
    marginBottom: "10px !important",
    textTransform: "uppercase",
    fontSize: 45
  }
});

class Managers extends Component {
  componentDidMount() {
    const { token, getManagers } = this.props;
    getManagers({ token });
  }
  searchManager = event => {
    const query = event.target.value;
    const { token, getManagers } = this.props;
    if (query.length) {
      query.length >= 4 && getManagers({ token, query });
    } else {
      getManagers({ token });
    }
  };
  changeManagerStatus = data => {
    const { token, changeStatus } = this.props;
    changeStatus({ token, managerId: data.managerId, status: data.newStatus });
  };

  render() {
    const { isloading } = this.props;
    return (
      <Fragment>
        <Row
          wrap
          horizontal="space-between"
          vertical="center"
          breakpoints={{ 768: "column" }}
          style={{ borderBottom: "2px solid lightgrey", paddingBottom: 10 }}
        >
          <Row horizontal="start">
            <h1 className={css(styles.heading)}>Managers</h1>
          </Row>
          <div className={css(styles.searchContainer)}>
            <input
              onChange={this.searchManager}
              type="text"
              name="search"
              placeholder="Search Managers"
              className={css(styles.searchInput)}
            />
          </div>
        </Row>
        <ManagersList changeStatus={this.changeManagerStatus} />
        {!!isloading && <Loader />}
      </Fragment>
    );
  }
}
const mapStateToProps = ({
  managers: { managers, isloading },
  admin: { token }
}) => ({ managers, isloading, token });
const mapDispatchToProps = dispatch => ({
  getManagers: data => dispatch(getManagersMiddleware(data)),
  changeStatus: data => dispatch(changeManagerStatusMiddleware(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(Managers);
